import { Tune } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setFilterShow } from '../features/Apartment/ApartmentSlice'

const styles = {
    container : {
        transform: 'rotate(270deg)',
        transformOrigin: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        cursor: 'pointer',
        borderRadius: '5px',
        p: 1,
        ':hover':{
            backgroundColor: 'grey',
            color: 'white'
        }
    },
    text: {
        color: '#FFE99A',
        
        fontWeight: 500,
        letterSpacing: '5px',
        fontSize: '20px'
    },
    icon: {
        color: 'white'
    }
}

const FilterButton = () => {
    const dispatch = useDispatch()
  return (
    <Box sx={{backgroundColor: 'black', color: 'yellow', maxWidth: '100px'}} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
       <Box onClick={() => {dispatch(setFilterShow(true))}} sx={styles.container}> 
       <Tune sx={{...styles.icon}}/>
        <Typography sx={styles.text}>FILTERI</Typography></Box>
    </Box>
  )
}

export default FilterButton