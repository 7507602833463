import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Divider, Icon, IconButton, Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getApartmentDetailData, setADetailModalState, setApartmentDetailData } from '../features/ApartmentDetailSlice';
import { checkIfIsInWishList } from '../features/WishlistSlice';
import { Apartment, Close, Favorite, FavoriteBorder } from '@mui/icons-material';

// {checkIfIsInWishList(id) ? <Favorite sx={{color: 'red', position: 'absolute', top: 1, right: 1}} fontSize='small' /> : null}
const style = {
  
  width: 250,
  height: 'auto',
  bgcolor: 'background.paper',
  pointerEvents: 'all',
  borderRadius: '20px',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: 4
};
const textStyle = {
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: 600,
}

 function ADetailsModal( { modalState, setState }) {
  const apartmentDetailData = useSelector(getApartmentDetailData)
  const { open, title, anchorEl, img, id, item } = modalState;
  const dispatch = useDispatch()
  const handleClose = () => { setState({
    ...modalState,
    open: false
  })
  };

  const handleMoreDetailsClicked = () => {
    dispatch(setApartmentDetailData(item))
    //client modal
    dispatch(setADetailModalState(true))
  }
  

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            borderRadius: "17px", // Set your desired borderRadius here
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {checkIfIsInWishList(id) ? (
          <Favorite
            sx={{color: "red", position: "absolute", top: 8, right: 8 }}
            fontSize="medium"
          />
        ) : (
          <FavoriteBorder
            sx={{ color: "black", position: "absolute", top: 8, right: 8 }}
            fontSize="medium"
          />
        )}
        {/* <IconButton 
          sx={{position: 'absolute', top: 1, left: 1, pointerEvents: 'all'}}
          onClick={() => {handleClose()}}

        >
          <Close />
        </IconButton> */}
        <Box sx={{ ...style }}>
          <img
            width={250}
            height={180}
            style={{
              objectFit: "contain",
              border: "none",
              marginTop: 20,
              marginBottom: 1,
            }}
            src={item.planMetric?.name === 'threed' ? item.planMetric.url : ''}
          />
          <Box
            width={"100%"}
            textAlign={"start"}
            p={1}
            sx={{
              backgroundColor: "rgba(255, 233, 154, 0.85)",
              borderRadius: "0px 50px 17px 17px",
            }}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
          >
            <Box display={"flex"} gap={1}>
              <Apartment />
              <Box display={"flex"}>
                <Typography style={textStyle}>{"Banesa: "}</Typography>
                <Typography style={{ ...textStyle, marginLeft: 10,fontWeight: "normal" }}>
                  {apartmentDetailData.name}
                </Typography>
                </Box>
            </Box>
            <Divider
              style={{
                border: "-1px solid #B7B7B7",
              }}
            />
            <Box display={"flex"} gap={1}>
              <Icon>
                <img src="/assets/roomSize.svg" />
              </Icon>
              <Box display={"flex"}>
                <Typography style={textStyle}>{"Siperfaqja: "}</Typography>
                <Typography style={{ ...textStyle, marginLeft: 10, fontWeight: "normal" }}>
                  {apartmentDetailData.square}
                  {"m2"}
                </Typography>
              </Box>
            </Box>
            <Divider
              style={{
                border: "-1px solid #B7B7B7",
              }}
            />
            <Box display={"flex"} gap={1}>
              <Icon>
                <img src="/assets/room.svg" />
              </Icon>
              <Box display={"flex"}>
                <Typography style={textStyle}>{"Tipi: "}</Typography>
                <Typography style={{ ...textStyle, marginLeft: 10,fontWeight: "normal" }}>
                  {' '}{apartmentDetailData.rooms}
                </Typography>
            </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
export default ADetailsModal