import { ArrowBack, Close, Download, Email, Favorite, FavoriteBorder, Phone, Photo, Preview, ViewAgenda, WhatsApp } from '@mui/icons-material'
import { Box, Button, Dialog, Divider, IconButton, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getADetailModalState, getApartmentDetailData, setADetailModalState } from '../features/ApartmentDetailSlice'
import ApartmentData from './ApartmentData'
import Icon360 from '../assets/Icon360'
import ThreeSixty from 'react-360-view'
import { checkIfIsInWishList, getWishListItems, handleRefreshWishListState, handleWishlistItem } from '../features/WishlistSlice'
import { homepage } from '../utils/consts'


const ADetailModal1 = () => {
    const [rotation, setRotation] = useState(0); // Current rotation angle
    const [isWishList, setWishList] = useState(false);
    const wishListItems = useSelector(getWishListItems)

  const rotateLeft = () => {
    setRotation(rotation - 10); // Rotate left by 10 degrees
  };

  const rotateRight = () => {
    setRotation(rotation + 10); // Rotate right by 10 degrees
  };
  const handleWishList = () => {
    if(checkIfIsInWishList(id)) {
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      const filteredList = storedArray.filter((item) => item !== id)
      
      localStorage.setItem('wish', JSON.stringify(filteredList))
      setWishList(false)
      dispatch(handleWishlistItem(wishListItems.filter((item) => item !== id)))
    }
    }
    else{
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      storedArray.push(id)
      
      localStorage.setItem('wish', JSON.stringify(storedArray))
    }
    else{
      const wishListToAdd = [
        id
      ]
      localStorage.setItem('wish', JSON.stringify(wishListToAdd))
    }
    setWishList(true)
    let wishToAdd = [...wishListItems];
    wishToAdd.push(id)
    dispatch(handleWishlistItem(wishToAdd))
    }
    dispatch(handleRefreshWishListState())
  }
  useEffect(() => {
     if(checkIfIsInWishList(id)){
      setWishList(true)
     }
  }, [])

  const theme = useTheme()
  const open = useSelector(getADetailModalState)
  const dispatch = useDispatch()
  const [threed, setThreed] = useState(true)
  const { name, apartmentNumber, square, terasa, floor, rooms, galleryImg, planMetric, pdfUrl, imageData, id } = useSelector(getApartmentDetailData)
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallDev ? '95%' : '90%',
    height: isSmallDev ? '100%' : '85%',
    bgcolor: "background.paper",
    boxShadow: 40,
    borderRadius: 5,
    p: isSmallDev ? 3 : 0,
    paddingRight: 5,
    overflow: isSmallDev ? 'auto' : 'hidden'
  };
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Box display={"flex"} flexDirection={isSmallDev ? "column" : "row"}>
          {isSmallDev ? (
            <Box display={"flex"} gap={2} ml={2} mt={isSmallDev ? 2 : 1}>
              <Typography variant={"h4"}>{"Banesat "}</Typography>
              <Typography variant={"h4"} fontWeight={700}>
                {" "}
                {name}
              </Typography>
            </Box>
          ) : null}
          {isSmallDev ? (
                <Box m={1} mt={2}>
                  <Button
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    borderRadius: "10px",
                    backgroundColor: "#FFE99A",
                    boxShadow: "none",
                    color: "black",
                    ":active": {
                      backgroundColor: "#b89201cc",
                      color: "white",
                    },
                    ":hover": {
                      backgroundColor: "black",
                      color: "#b89201cc",
                    },
                  }}
                  startIcon={<Download />}
                  variant="contained"
                  onClick={() => {
                    if (planMetric.name === "planmetria")
                      window.open(`${homepage}${planMetric.url}`, "_blank");
                  }}
                >
                  Shkarko PDF
                </Button>
                </Box>
              ) : null}
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            flex={3}
            height={"100%"}
            mt={2}
            overflow={"hidden"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              gap={2}
            >
              {threed ? <img
                width={isSmallDev ? "100%" : "70%"}
                height={isSmallDev ? "100%" : "75%"}
                style={{ objectFit: "contain", maxHeight: "fit-content", marginLeft: isSmallDev ? 15 : 'unset' }}
                src={planMetric.name === "threed" ? planMetric.url : ''}
              /> : <img
              width={isSmallDev ? "100%" : "70%"}
              height={isSmallDev ? "100%" : "75%"}
              style={{ objectFit: "contain", maxHeight: "fit-content", marginLeft: isSmallDev ? 15 : 'unset' }}
              src={"data:image/jpeg;base64," + imageData}
            />}
              <Box
                display={"flex"}
                pl={isSmallDev ? 2 : 0}
                width={isSmallDev ? "95%" : "unset"}
                justifyContent={isSmallDev ? "space-between" : "center"}
                sx={{ gap: { xs: 2, md: "55px" } }}
              >
                <Button
                onClick={() => setThreed(false)}
                  sx={{
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    borderRadius: "10px",
                    color: "black",
                    borderColor: "black",
                    boxShadow: "none",
                    height: isSmallDev ? 50 : "50px",
                    width: isSmallDev ? "40%" : "241px",
                  }}
                  startIcon={<Photo />}
                  variant="outlined"
                >
                  2D Image
                </Button>
                <Button
                onClick={() => setThreed(true)}
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    borderRadius: "10px",
                    backgroundColor: "#FFE99A",
                    color: "black",
                    boxShadow: "none",
                    height: isSmallDev ? 50 : "50px",
                    width: isSmallDev ? "40%" : "241px",
                    ":active": {
                      backgroundColor: "#b89201cc",
                      color: "white",
                    },
                    ":hover": {
                      backgroundColor: "black",
                      color: "#b89201cc",
                    },
                  }}
                  variant="contained"
                >
                  3D Image
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: isSmallDev ? 70 : 55,
              height: isSmallDev ? 70 : 55,
              backgroundColor: "#FFE99A",
              borderBottomLeftRadius: 25,
            }}
          ></Box>
          <IconButton
            sx={{
              position: "absolute",
              top: isSmallDev ? 15 : 2,
              right: isSmallDev ? 15 : 1,
            }}
            onClick={() => {
              dispatch(setADetailModalState(false));
            }}
            startIcon={<ArrowBack />}
            variant="contained"
          >
            <Close fontSize="large" />
          </IconButton>
          <IconButton
            onClick={handleWishList}
            sx={{
              position: "absolute",
              top: isSmallDev ? 70 : 2,
              right: isSmallDev ? 15 : 55,
              color: isWishList ? "red" : "black",
            }}
          >
            {isWishList ? (
              <Favorite fontSize="large" />
            ) : (
              <FavoriteBorder fontSize="large" />
            )}
          </IconButton>
          <Box
            m={2}
            mt={6}
            flex={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              {isSmallDev ? null : (
                <Box display={"flex"} gap={2}>
                  <Typography variant={isSmallDev ? "h5" : "h4"}>
                    {"Banesat "}
                  </Typography>
                  <Typography
                    variant={isSmallDev ? "h5" : "h4"}
                    fontWeight={700}
                  >
                    {" "}
                    {name}
                  </Typography>
                </Box>
              )}
              {isSmallDev ? null : (
                <Button
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    borderRadius: "10px",
                    backgroundColor: "#FFE99A",
                    boxShadow: "none",
                    fontWeight: 600,
                    color: "black",
                    ":active": {
                      backgroundColor: "#b89201cc",
                      color: "white",
                    },
                    ":hover": {
                      backgroundColor: "black",
                      color: "#b89201cc",
                    },
                  }}
                  startIcon={<Download />}
                  variant="contained"
                  onClick={() => {
                    if (planMetric.name === "planmetria")
                      window.open(`${homepage}${planMetric.url}`, "_blank");
                  }}
                >
                  Shkarko PDF
                </Button>
              )}
            </Box>
            <Box
              marginY={isSmallDev ? 0 : 10}
              marginX={isSmallDev ? "10px" : 0}
            >
              <Divider sx={{ marginY: 2 }} />
              <ApartmentData
                title={"Siperfaqja(Bruto)"}
                value={square + " m2"}
              />
              <Divider sx={{ marginY: 2 }} />
              <ApartmentData title={"Kati"} value={floor} />
              <Divider sx={{ marginY: 2 }} />
              <ApartmentData title={"Objekti"} value={apartmentNumber} />
              <Divider sx={{ marginY: 2 }} />
              <ApartmentData title={"Tipi"} value={rooms} />
              <Divider sx={{ marginY: 2 }} />
            </Box>
            <Box
              display={"flex"}
              gap={2}
              flexDirection={"row"}
              justifyContent={isSmallDev ? "space-between" : "flex-end"}
              width={isSmallDev ? "97%" : "unset"}
              bottom={5}
              left={1}
              mt={isSmallDev ? 5 : 'unset'}
            >
              <Button
                href="mailto: roitiv@gmail.com?body=`Jam i interesuar rreth baneses`"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  fontSize: { md: "16px", xs: "12px" },
                  padding: { md: "19px 40px", xs: "10px 20px" },
                  fontWeight: 600,
                  boxShadow: "none",
                  borderRadius: "7px",
                  flexGrow: 1,
                  fontFamily: "Montserrat",
                  ":active": {
                    backgroundColor: "#473400",
                    color: "white",
                  },
                  ":hover": {
                    backgroundColor: "#473400",
                    color: "white",
                  },
                }}
                startIcon={<Email sx={{ fontSize: "0.8rem" }} />}
                variant="contained"
              >
                Na kontakoni
              </Button>
              <Button
                startIcon={<WhatsApp fontSize="inherit" />}
                sx={{
                  backgroundColor: "rgba(131, 131, 131, 0.2)",
                  color: "black",
                  paddingLeft: "20px",
                  borderRadius: "7px",
                  ":active": {
                    backgroundColor: "#473400",
                    color: "white",
                  },
                  ":hover": {
                    backgroundColor: "#473400",
                    color: "white",
                  },
                }}
              />
              <Button
                startIcon={<Phone fontSize="inherit" />}
                sx={{
                  backgroundColor: "rgba(131, 131, 131, 0.2)",
                  color: "black",
                  paddingLeft: "20px",
                  borderRadius: "7px",
                  ":active": {
                    backgroundColor: "#473400",
                    color: "white",
                  },
                  ":hover": {
                    backgroundColor: "#473400",
                    color: "white",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box width={150} height={150}></Box>
        {/* <Box display={"flex"} justifyContent={"center"}>
          <GalleryCard />
          <GalleryCard />
        </Box> */}
      </Box>
    </Modal>
  );
}

export default ADetailModal1