import { Done } from '@mui/icons-material';
import axios from 'axios';
import React, { useState } from 'react';
import { BASE_URL } from '../utils/consts';
import { tokenConfig } from '../features/AuthSlice';

const SvgExtractor = () => {
  const [svgCode, setSvgCode] = useState('');
  const[elementIndex, setElementIndex] = useState(0);
  const [extractedElements, setExtractedElements] = useState(
    {
      attr: [],
      paths: [],
    }
  );

  const handleSvgCodeChange = (event) => {
    setSvgCode(event.target.value);
  };

  const [inputVal, setInputVal] = useState({
    name: '',
    apartmentNumber: '',
    rooms: '',
    floor: 0,
    square: 0,
    balconySquare: 0,
    buildingSide: 0,
    isSold: false,
    buildingNr: '',
    maxFloor: 0,
  })
  
  const extractSvgElements = (selectedTags) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgCode, 'image/svg+xml');
    const elements = [];
    const pathsList = []
    const svgElement = doc.querySelector('svg');
    if (svgElement) {
      const viewBox = svgElement.getAttribute('viewBox');
      elements.push({ type: 'viewBox', value: viewBox });
    }
    const imgElement = doc.querySelector('image');
    console.log(doc)
    if (imgElement) {
      const width = imgElement.getAttribute('width');
      const height = imgElement.getAttribute('height')
      const transform = imgElement.getAttribute('transform')
      elements.push({ type: 'height', value: height });
      elements.push({ type: 'width', value: width });
      if(transform){
        elements.push({ type: 'transform', value: transform });
      }
    }
    
    selectedTags.forEach((tag) => {
      const selectedElements = doc.querySelectorAll(tag);
      selectedElements.forEach((element) => {
        const attributes = Array.from(element.attributes);
        const attributesObject = attributes.reduce((acc, attr) => {
          if(attr.name === 'points' || attr.name === 'd'){
            acc["path"] = attr.value
            
            if(attr.name == 'points'){
              acc['pointsType'] = 'polygon';
            }
            if(attr.name === 'd'){
              acc['pointsType'] = 'path';
            }
            return acc;
          }
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        pathsList.push(attributesObject);
      });
    });

    setExtractedElements({
      ...extractSvgElements,
      attr: elements,
      paths: pathsList
    });
  };
  const handleAddAdditionalAttrToList = () => {
    const arrayToSave = extractedElements.paths[elementIndex];
    arrayToSave['name'] = inputVal.name
    arrayToSave['square'] = inputVal.square
    arrayToSave['floor'] = inputVal.floor
    arrayToSave['rooms'] = inputVal.rooms
    arrayToSave['balconySquare'] = inputVal.balconySquare
    console.log(inputVal.name)
    setExtractedElements({
      ...extractedElements,
      
    })
    
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      svgAttributesList: extractedElements.attr,
      svgPathsList: extractedElements.paths
    };
      const response = axios.post(`${BASE_URL}/api/apartment/create/list`, data, tokenConfig())
        .then((res) => {
          if (response.status === 200) {
            console.log("User created successfully");
          }
        })
        .catch((error) => {
          console.error("An error occurred", error);
        });
        
    }
  
  function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const handleRandomGenerateAll = () => {
    const arrayToSave = extractedElements.paths;
  
    for (let i = 0; i < arrayToSave.length; i++) {
      const randomNumber =  `Njesia ${i+1}`;
      const squarenr = generateRandomNumber(59, 141)
      arrayToSave[i].name = randomNumber;
      const nr =  Math.floor(Math.random() * 4) + 1;
      arrayToSave[i].rooms = `${nr}+${1}`
      arrayToSave[i].isSold = false
      arrayToSave[i].apartmentNumber = 95
      arrayToSave[i].floor = nr
      arrayToSave[i].square = squarenr
      arrayToSave[i].buildingNr = 0
      arrayToSave[i].balconySquare = generateRandomNumber(1, 20)
      arrayToSave[i].maxFloor = inputVal.maxFloor
      arrayToSave[i].minSquare = 0
      arrayToSave[i].maxSquare = 0

    }
    setExtractedElements({
      ...extractedElements,
      
      
    })
  }

  const addBuildingSide = () => {
    
    const arrayToSave = extractedElements.paths;
  
    for (let i = 0; i < arrayToSave.length; i++) {
      arrayToSave[i].buildingSide = inputVal.buildingSide
  }
  setExtractedElements({
    ...extractedElements,
    
    
  })
}
const addBuildingNr = () => {
    
  const arrayToSave = extractedElements.paths;

  for (let i = 0; i < arrayToSave.length; i++) {
    arrayToSave[i].buildingNr = inputVal.buildingNr
}
setExtractedElements({
  ...extractedElements,
  
  
})
}
  
  return (
    <div style={{margin: 5, padding: 5}}>
      <div style={{display: 'flex', gap: 20}}>
        <div><textarea
        rows={10}
        cols={50}
        placeholder="Paste SVG code here"
        value={svgCode}
        onChange={handleSvgCodeChange}
      />
      <div>
        <label>
          <input type="checkbox" value="image" /> Image
        </label>
        <label>
          <input type="checkbox" value="path" /> Path
        </label>
        <label>
          <input type="checkbox" value="polygon" /> Polygon
        </label>
      </div>
      <button
        onClick={() => {
          const selectedTags = ['image', 'path', 'polygon'].filter(
            (tag) => document.querySelector(`input[value="${tag}"]`).checked
          );
          extractSvgElements(selectedTags);
        }}
      >
        Extract Elements
      </button>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div>
          <div style={{display: 'flex', gap: 20}}>
          <label>Antari: {elementIndex}</label>
          <label>Gjithsej: {extractedElements.paths.length} Antar ne List</label>
          <label>{extractedElements.paths.length - elementIndex} Antar Te Mbetur</label>
          </div>
          <div>
          <button onClick={() => {
            if(elementIndex >= extractedElements.paths.length) {return}
            setElementIndex(elementIndex + 1)
            const arrayToSave = extractedElements.paths[elementIndex];
            setInputVal({
              ...inputVal,
              square: arrayToSave['square'],
              name: arrayToSave['name'],
              floor: arrayToSave['floor'],
              rooms: arrayToSave['rooms'],
              balconySquare: arrayToSave['balconySquare'],
            })
          }}>Antari Radhes</button>
        </div>
        </div>
        <div style={{display: 'flex', gap: 10, flexWrap: 'wrap'}}>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Siperfaqja
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                square: e.currentTarget.value
              })
            }} value={inputVal.square} type='text' name='square'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Kati
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                floor: e.currentTarget.value
              })
            }} value={inputVal.floor} type='text' name='floor'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Tipi
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                rooms: e.currentTarget.value
              })
            }} value={inputVal.rooms} type='text' name='rooms'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Ndertesa
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                buildingNr: e.currentTarget.value
              })
            }} value={inputVal.buildingNr} type='text' name='buildingNr'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Nr. Baneses
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                apartmentNumber: e.currentTarget.value
              })
            }} value={inputVal.apartmentNumber} type='text' name='apartmentName'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Terrasa
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                balconySquare: e.currentTarget.value
              })
            }} value={inputVal.balconySquare} type='text' name='balconySquare'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Ana e Baneses
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                buildingSide: e.currentTarget.value
              })
            }} value={inputVal.buildingSide} type='text' name='buildingSide'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Emri Baneses
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                name: e.currentTarget.value
              })
            }} value={inputVal.name} type='text' name='apartmentName'/>
          </label>
          <label style={{ display: 'flex', flexDirection: 'column'}}>
            Kati maksimal
            <input onChange={(e) => {
              setInputVal({
                ...inputVal,
                maxFloor: parseInt(e.currentTarget.value)
              })
            }} value={inputVal.maxFloor} type='number' name='maxFloor'/>
          </label>
          <label >
            
            <input type='checkbox' name='isSold'/>Shitur
          </label>
        </div>
        
       <div style={{display: 'flex', gap: 10, justifyContent: 'space-between'}}>
       <div style={{display: 'flex', gap: 10,}}>
       <div>
          <button
            onClick={handleAddAdditionalAttrToList}
          >Shto ne List</button>
        </div>
        <div>
          <button onClick={() => {
            handleRandomGenerateAll()
          }}>Gjenero Random Vlerat</button>
        </div>
        <div>
          <button onClick={addBuildingSide}>Shto Building Side</button>
        </div>
        <div>
          <button onClick={addBuildingNr}>Shto Building Number</button>
        </div>
       </div>
        <div>
          <button onClick={handleSubmit} style={{display: 'flex', alignItems: 'center', fontWeight: '500px'}}><Done />Ruaj Ne Server</button>
        </div>
       </div>
      </div>
      </div>
      
      <div style={{overflow: 'auto', maxWidth: 'fit-content', maxHeight: 'fit-content', height: '500px', marginTop: 50, userSelect: 'text'}}>
        Lista perfundimtare:
        <pre>{JSON.stringify(extractedElements, null, 2)}</pre>
      </div>
    </div>
  );
};

export default SvgExtractor;
