export const getDataToSave = () => {
    const path = [
      {
        "path": '1512.48 446.01 1516.87 446.01 1576.35 456.78 1577.49 331.47 1517.58 313.16 1464.48 319.58 1318.59 277.91 982.12 331.77 982.12 450.54 1139.49 434.15 1139.3 428.57 1153.9 427.72 1305.36 411.8 1322.18 410.48 1512.48 446.01'
      },
      {
        "path": '797.02 359.27 682.69 378.35 628.92 371.88 567.79 383.27 567.79 389.13 575.72 390.26 576.1 439.2 570.43 439.2 570.67 473.79 569.25 473.79 569.25 484.13 630.62 477.61 683.77 481.72 788.51 470.39 770.09 468.47 770.09 449.31 772.12 449.31 771.74 411.52 797.39 408.02 797.02 359.27'
      },
      {
        "path": '1576.35 456.78 1576.12 576.4 1516.59 573.76 1512.62 574.24 1322.24 562.87 1140.08 568.96 1140.08 573.5 982.12 578.67 982.12 450.6 1139.54 434.39 1139.3 428.57 1322.18 410.48 1576.35 456.78'
      },
      {
        "path": '1576.12 576.4 1576.12 695.65 1514.75 697.91 1511.63 697.91 1511.63 702.26 1336.31 713.17 1305.55 713.6 1140.01 707.74 1140.01 699.24 982.12 695.08 982.12 578.67 1140.08 573.5 1140.08 568.96 1322.24 562.87 1576.12 576.4'
      },
      {
        "path": '1576.12 695.65 1576.12 814.89 1513.99 826.8 1510.21 829.44 1321.43 864.59 1140.29 846.54 1140.29 830.95 982.12 816.02 982.12 695.08 1140.01 699.24 1140.01 707.74 1305.55 713.6 1322.28 713.39 1336.63 713 1511.63 702.26 1511.63 697.91 1576.12 695.65'
      },
      {
        "path": '1576.12 814.89 1576.12 932.72 1513.61 952.56 1509.79 952.56 1509.79 955.96 1320.67 1014.83 1140.29 983.17 1140.29 961.91 982.12 935.46 982.12 816.02 1140.29 830.95 1140.29 846.54 1321.43 864.59 1510.21 829.44 1513.99 826.8 1576.12 814.89'
      },
      {
        "path": '1576.12 932.72 1576.12 1035.61 1565.39 1041.85 1550.09 1054.51 1513.05 1068.87 1507.38 1067.36 1387.47 1119.24 1379.39 1116.97 1368.76 1121.22 1368.76 1142.48 1321.43 1163.17 1141.14 1119.52 1141.14 1085.13 982.12 1047.94 982.12 935.46 1140.29 961.91 1140.29 983.17 1320.67 1014.83 1509.79 955.96 1509.79 952.56 1513.61 952.56 1576.12 932.72'
      },
      {
        "path": '982.12 578.67 899.35 580.94 845.49 579.8 771.46 581.6 771.46 563.88 773.68 563.88 773.77 526.37 797.39 523.96 797.39 470.67 788.51 470.39 770.09 468.47 770.09 456.78 843.79 450.54 899.91 456.78 982.12 450.6 982.12 578.67'
      },
      {
        "path": '982.12 695.08 901.14 692.43 901.14 696.4 846.53 697.72 772.83 695.08 772.83 678.21 770.75 678.21 770.75 677.22 774.95 677.22 774.39 639.24 797.39 639.24 797.23 582.5 771.46 581.6 845.49 579.8 899.35 580.94 982.12 578.67 982.12 695.08'
      },
      {
        "path": '982.12 816.02 901.71 808.09 901.71 809.79 847.28 816.02 774.06 808.09 773.49 789.66 775.76 789.66 775.28 752.15 798.72 750.45 798.43 696 846.53 697.72 901.14 696.4 901.14 692.43 982.12 695.08 982.12 816.02'
      },
      {
        "path": '982.12 935.46 902.46 921.66 902.46 923.72 848.18 932.79 774.62 919.75 774.62 902.6 773.42 902.6 773.42 901.61 776.89 901.32 776.51 864.4 800.42 861.57 800.42 810.85 847.28 816.02 901.71 809.79 901.71 808.09 982.12 816.02 982.12 935.46'
      },
      {
        "path": '982.12 1047.94 903.98 1032.4 903.98 1036.18 849.55 1049.98 776.32 1031.22 776.32 1013.36 777.79 1012.89 777.6 976.37 804.67 970.7 804.67 924.94 848.18 932.79 902.46 923.72 902.46 921.66 982.12 935.46 982.12 1047.94'
      },
      {
        "path": '797.39 470.67 797.39 523.96 773.77 526.37 773.68 563.88 771.46 563.88 771.46 581.6 797.23 582.5 685.14 584.53 632.04 584.06 570.91 585.47 571.8 539.83 576.86 539.74 576.29 489.85 569.16 489.19 569.25 484.13 630.62 477.61 683.77 481.72 789.17 470.41 797.39 470.67'
      },
      {
        "path": '797.23 582.5 797.39 639.24 774.39 639.24 774.95 677.22 770.75 677.22 770.75 678.21 772.83 678.21 772.83 687.09 686.84 685.44 633.31 685.96 572.09 685.11 572.09 672.07 574.35 672.07 574.35 639.24 577.99 639.14 577.99 588.87 570.81 588.87 570.91 585.47 632.04 584.06 686 584.55 797.23 582.5'
      },
      {
        "path": '772.83 687.09 772.83 695.08 798.43 696 798.72 750.45 775.28 752.15 775.76 789.66 773.49 789.66 688.17 781.72 634.69 785.13 573.5 780.07 573.5 770.82 575.67 770.89 575.35 737.76 579.88 737.76 579.88 688.37 572.13 688.37 572.13 685.11 633.31 685.96 686.84 685.44 772.83 687.09'
      },
      {
        "path": '773.49 789.66 774.06 808.09 800.42 810.85 800.42 861.57 776.51 864.4 776.89 901.32 773.42 901.61 689.2 885.57 635.87 892.61 574.87 882.35 574.31 869.41 577.71 869.22 577.19 836.34 582.29 835.77 581.2 787.3 573.65 787.3 573.5 780.07 634.69 785.13 688.17 781.72 773.49 789.66'
      },
      {
        "path": '774.62 902.6 774.62 919.75 804.67 924.94 804.67 970.7 777.6 976.37 777.74 1002.83 689.77 983.46 637.14 992.53 576.57 977.13 576.57 968.34 578.94 967.87 578.94 934.89 583.57 933.85 583.28 886.37 575.06 885.02 574.87 882.35 635.87 892.61 689.2 885.57 773.42 901.61 773.42 902.6 774.62 902.6'
      },
      {
        "path": '567.79 383.27 567.79 372.59 570.81 372.59 569.3 339.99 574.64 338.43 574.64 292.56 578.94 291.24 585.46 292.46 585.46 293.41 609.93 297.76 609.93 290.48 612.76 290.48 612.06 249.43 795.69 202.23 797.02 359.27 682.69 378.35 628.92 371.88 567.79 383.27'
      },
      {
        "path": '574.64 292.56 570.15 293.41 570.15 312.97 449.98 293.17 275.48 339.53 275.33 340.95 276.43 340.95 276.79 376.49 249.86 373.94 249.57 372.8 243.48 372.45 238.87 374.01 239.82 409.06 235.28 409.77 235.28 423.71 168.1 437.31 168.67 459.47 166.67 459.65 166.67 460.93 167.99 460.93 167.77 475.68 170.84 475.68 171.79 526.65 167.91 526.65 167.97 528.02 169.32 528.02 169.28 542.46 172.65 542.46 173.2 593.6 170.09 593.6 170.42 609.9 174.06 609.9 175.17 661.16 171.13 661.16 171.91 676.61 175.45 676.61 176.58 727.91 173.25 727.91 173.25 742.8 177.36 742.8 178.4 794.29 174.81 794.29 175.59 809.69 178.92 810.12 180.2 861.38 176.7 861.38 176.98 876.12 180.48 876.59 180.46 887.53 570.81 980.06 571.28 979.39 576.57 979.49 576.57 977.13 576.57 968.34 578.94 967.87 578.94 934.89 583.57 933.85 583.28 886.37 575.06 885.02 574.31 869.41 577.71 869.22 577.19 836.34 582.29 835.77 581.2 787.3 573.65 787.3 573.5 770.82 575.67 770.89 575.35 737.76 579.88 737.76 579.88 688.37 572.13 688.37 572.09 672.07 574.35 672.07 574.35 639.24 577.99 639.14 577.99 588.87 570.81 588.87 571.8 539.83 576.86 539.74 576.29 489.85 569.16 489.19 569.25 473.79 570.67 473.79 570.43 439.2 576.1 439.2 575.72 390.26 567.79 389.13 567.79 372.59 570.81 372.59 569.3 339.99 574.64 338.43 574.64 292.56'
      },
      {
        "path": '795.69 202.23 821.3 194.95 975.98 234.92 1078.64 213.28 1078.64 193.44 1229.2 158.95 1264.92 171.8 1460.04 128.95 1681.85 234.78 1681.85 236.57 1680.62 236.57 1680.1 293.03 1675.57 293.03 1674.93 381.66 1637.96 371.55 1638.15 350.01 1517.58 313.16 1464.48 319.58 1318.59 277.91 982.12 331.77 797.02 359.27 795.69 202.23'
      },
      {
        "path": '1637.96 371.55 1674.93 381.66 1674.43 423.9 1741.71 441.09 1741.61 473.69 1743.79 473.98 1737.74 981.76 1633.05 1027.49 1576.12 1035.61 1577.49 331.47 1638.15 350.01 1637.96 371.55'
      }
    ]
          for (let i = 0; i < path.length; i++) {
            const randomNumber =  `Njesia ${i+1}`;
            const squarenr = generateRandomNumber(1, 250000)
            path[i].name = randomNumber;
            const nr =  Math.floor(Math.random() * 7) + 1;
            path[i].rooms = `${nr}+${1}`
            path[i].isSold = false
            path[i].apartmentNumber = 95
            path[i].floor = nr
            path[i].square = squarenr
            path[i].buildingNr = 0
            path[i].balconySquare = generateRandomNumber(1, 20)
          }
          
    return path;      
  }
  function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }