import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Photo } from "@mui/icons-material";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  getApartmentEdit,
  setApartmentEditModalState,
} from "../features/ApartmentEditSlice";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../utils/consts";
import axios from "axios";
import { tokenConfig } from "../features/AuthSlice";
import { getFilterMsg, getFilterStatus } from "../features/Apartment/ApartmentSlice";
import { toast } from "react-toastify";
import { getAllApartments, getAllApartmentsById, updateApartment } from "../features/Apartment/ApartmentAPI";
import { base64ToBlob } from "../utils/converts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 40,
  p: 2,
};

export default function BasicModal({buildId}) {
  const [open, setOpen] = React.useState(false);
  const apartmentEditData = useSelector(getApartmentEdit);
  const [selectedImg, setSelectedImg] = React.useState(null);
  const inputRef = React.useRef(null);
  const dispatch = useDispatch();
  const msg = useSelector(getFilterMsg)
  const status = useSelector(getFilterStatus)
  const [apartmentData, setApartmentData] = React.useState({
    id: "",
    rooms: 1,
    isSold: false,
    floor: 0,
    square: 0,
    name: "",
    imageData: null,
    apartmentNumber: 1,
    style: "",
    className: "",
    path: "",
    apartmentId: "",
    balconySquare: 0,
    imgUrl: '',
    comments: '',
  });
  React.useEffect(() => {
    if (apartmentEditData.data !== null) {
      setApartmentData(apartmentEditData.data);
      let namee = ''
      const name = apartmentEditData.data.planMetric?.name?.split(',')
      const url = apartmentEditData.data.planMetric?.url?.split(',')
      for (let index = 0; index < name?.length; index++) {
        
        if(index < name.length -1 ){
          namee += name[index] + ',' + url[index] + ';'
        }
        else{
          namee += name[index] + ',' + url[index]
        }
      }
      setApartmentData((prev) => (
        {...prev,
        imgUrl: namee,
        }
      ))
      
      setSelectedImg('data:image/png;base64,' + apartmentEditData.data.imageData)
     if(apartmentEditData.data.imageData?.length > 0){
      const contentType = 'image/png'; // Replace with the appropriate content type

      // Convert base64 to Blob
      const binaryBlob = base64ToBlob(apartmentEditData.data.imageData, contentType);

      // Create a File object from the Blob (optional)
      const file = new File([binaryBlob], 'image.png', { type: contentType });
      setApartmentData((prev) => (
        {...prev,
        imageData: file,
        }
      ))
     }
            
    }
  }, [apartmentEditData]);

  React.useEffect(() => {
    if (status === 'update_success') {
      toast.success(
        'Dokumenti u ruaj me sukses', {
          position: 'top-right',
          onClose: () => {
            dispatch(getAllApartmentsById(buildId))
          }
        }
      )
      dispatch(setApartmentEditModalState(false));
    }
    if (status === 'update_rejected') {
      toast.error(
        'Gabim ne ruajtjen e dokumentit', {
          position: 'top-right',
        }
      )
    }
  }, [status]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let imageName = []
    let imagePath = []
    const imagePaths = apartmentData.imgUrl?.split(';')
    imagePaths?.forEach((item) => {
      const data = item.split(',')
      imageName.push(data[0])
      imagePath.push(data[1])
    })
    const formData = new FormData();
    formData.append("rooms", apartmentData.rooms);
    formData.append("isSold", apartmentData.isSold);
    formData.append("floor", apartmentData.floor);
    formData.append("square", apartmentData.square);
    formData.append("name", apartmentData.name);
    formData.append("apartmentNumber", apartmentData.apartmentNumber);
    formData.append("style", apartmentData.style);
    formData.append("className", apartmentData.className);
    formData.append("imageData", apartmentData.imageData);
    formData.append("path", apartmentData.path);
    formData.append("apartmentId", apartmentData.apartmentId);
    formData.append("balconySquare", apartmentData.balconySquare);
    formData.append("id", apartmentData.id);
    formData.append("planMetricName", imageName);
    formData.append("planMetricUrl", imagePath);
    formData.append("comments", apartmentData.comments);
    dispatch(updateApartment(formData))
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImg(e.target.result);
        setApartmentData((prev) => ({
          ...prev,
          imageData: file,
        }));
      };

      reader.readAsDataURL(file);
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display={"flex"} gap={1} p={4}>
          <Box flex={2} >
            <Box>
              <img
                style={{
                  border: "1px solid gray",
                  borderRadius: 8,
                }}
                width={250}
                height={200}
                src={selectedImg}
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              <Button
                startIcon={<Photo />}
                variant="outlined"
                onClick={(e) => {
                  inputRef.current.click();
                }}
              >
                Shto Foton
              </Button>
              <input
                accept="image/*"
                onChange={handleFileChange}
                ref={inputRef}
                id="fileOpen"
                type="file"
                style={{ display: "none" }}
              />
            </Box>
            <Box maxHeight={120} height={100} overflow={'auto'}>
            <TextField 
            multiline
            label='Photo URL (Ndaj me ",")'
            fullWidth
            sx={{marginTop: 2}}
            value={apartmentData.imgUrl}
            name="imgUrl"
            onChange={(e) => {
              setApartmentData(
                {...apartmentData,
                imgUrl: e.target.value
                }
              )
            }}
            />
            </Box>
          </Box>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            light={false}
            flexItem
            sx={{ marginX: 2 }}
          />
          <Box flex={5}>
            <Grid container spacing={1} marginTop={1}>
              <Grid item sm={12} md={6}>
                <TextField
                  value={apartmentData.square}
                  onChange={(e) => {
                    setApartmentData((prev) => ({
                      ...prev,
                      square: e.target.value,
                    }));
                  }}
                  name="square"
                  label="Siperfaqja m2"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  name="floor"
                  value={apartmentData.floor}
                  onChange={(e) => {
                    setApartmentData((prev) => ({
                      ...prev,
                      floor: e.target.value,
                    }));
                  }}
                  label="Kati"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  name="rooms"
                  value={apartmentData.rooms}
                  onChange={(e) => {
                    setApartmentData((prev) => ({
                      ...prev,
                      rooms: e.target.value,
                    }));
                  }}
                  label="Numri i dhomave"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  name="name"
                  value={apartmentData.name}
                  onChange={(e) => {
                    setApartmentData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                  }}
                  label="Emri baneses"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  name="apartmentNumber"
                  value={apartmentData.apartmentNumber}
                  onChange={(e) => {
                    setApartmentData((prev) => ({
                      ...prev,
                      apartmentNumber: e.target.value,
                    }));
                  }}
                  label="Objekti"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextField
                  name="balconySquare"
                  value={apartmentData.balconySquare}
                  onChange={(e) => {
                    setApartmentData((prev) => ({
                      ...prev,
                      balconySquare: e.target.value,
                    }));
                  }}
                  label="Terasa m2"
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  name="apartmentId"
                  label="ID e Apartamentit"
                  value={apartmentData.apartmentId}
                  onChange={(e) => {
                    setApartmentData((prev) => ({
                      ...prev,
                      apartmentId: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Disponueshmeria
                  </FormLabel>
                  <RadioGroup
                    value={apartmentData.isSold}
                    row={true}
                    onChange={(e) => {
                      setApartmentData((prev) => ({
                        ...prev,
                        isSold: e.target.value,
                      }));
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Shitur"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Jo e shitur"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} overflow={'auto'} height={150}>
                  <TextField
                    label='Përshkrimi'
                    fullWidth
                    multiline
                    value={apartmentData?.comments ? apartmentData.comments : ''}
                    onChange={(e) => {
                      setApartmentData((prev) => ({
                        ...prev,
                        comments: e.target.value,
                      }));
                    }}
                  />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box display={"flex"} gap={2}>
          <Button variant="contained" onClick={handleSubmit}>
            Ruaj
          </Button>
          <Button
            onClick={() => {
              dispatch(setApartmentEditModalState(false));
            }}
            variant="contained"
          >
            Anulo
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
