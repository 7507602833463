import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false,
  data: {
    apartmentName: '',
    apartmentNumber: '',
    square: '',
    terasa: '',
    floor: '',
    room: '',
    galleryImg: '',
    planmetricImg: '',
    pdfUrl: '',
    planImageUrl: '',
  },
};

export const ApartmentDetailSlice = createSlice({
    name: 'ApartmentDetailSlice',
    initialState,
    reducers: {
        setApartmentDetailData (state, action){
            state.data = action.payload
        },
        setADetailModalState (state, action) {
            state.open = action.payload
        }
    }
})

export const {
    setApartmentDetailData,
    setADetailModalState,

} = ApartmentDetailSlice.actions
export const getApartmentDetailData = (state) => state.ApartmentDetailSlice.data
export const getADetailModalState = (state) => state.ApartmentDetailSlice.open

export default ApartmentDetailSlice.reducer;