import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearStatus, getAuthState, getLoginModalState, handleLoginModalState, login } from '../features/AuthSlice';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
import md5 from 'md5';
import { SHA1 } from 'crypto-js';
import CryptoJS from 'crypto-js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 5,
    p: 4,
  };

const Login = () => {
    const [isPassVisible, setPassVisible] = useState(false);
    const dispatch = useDispatch()
    const authStatus = useSelector(getAuthState)
    const isOpen = useSelector(getLoginModalState)
    const navigate = useNavigate()
    const [loginData, setLoginData] = useState({
        username: '',
        password: '',
    })
    useEffect(() => {
        if(authStatus === 'login_success'){
            dispatch(handleLoginModalState(false))
            dispatch(clearStatus())
            toast.success('Jeni kyqur ne sistem me sukses', {
                position: 'top-center',
                onClose: () => {
                    
                    
                }
            })
            navigate('/')
        }
        if(authStatus === 'login_rejected'){
            dispatch(handleLoginModalState(false))
            dispatch(clearStatus())
            toast.error("Error Logging in", {
                position: 'top-right',
            })
        }
    }, [authStatus])
    
    const handleLogin = () => {
        
        dispatch(login({
            username: loginData.username,
            password: SHA1(loginData.password).toString(CryptoJS.enc.Hex),
        }))
    }

  return (
    <Modal open={isOpen}>
        <Box sx={style}>
        <ToastContainer />
            <Typography variant='h4' textAlign={'center'}>Login</Typography>
            <Box marginY={2} marginX={2}>
            <Divider sx={{borderWidth: 1, borderColor: 'black'}} />
            </Box>
            <Grid container spacing={2} padding={3}>
                <Grid item sm={12} md={12}>
                    <TextField 
                        fullWidth
                        label={'Username or email'}
                        name='username'
                        value={loginData.username}
                        onChange={(e) => {
                            setLoginData(
                                {
                                    ...loginData,
                                    username: e.currentTarget.value
                                }
                            )
                        }}
                     />
                </Grid>
                <Grid item sm={12} md={12}>
                    <TextField 
                        fullWidth
                        type={isPassVisible ? 'text' : 'password'}
                        label={'Password'}
                        name='password'
                        value={loginData.password}
                        onChange={(e) => {
                            setLoginData(
                                {
                                    ...loginData,
                                    password: e.currentTarget.value
                                }
                            )
                        }}
                        InputProps={
                            {
                                endAdornment:(
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => {
                                            setPassVisible(!isPassVisible)
                                        }}>
                                            {
                                                isPassVisible ? <VisibilityOff /> : <Visibility />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }
                     />
                </Grid>
            </Grid>
            <Box paddingX={3} paddingBottom={1}>
            <Typography>Forgot Password ?</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} paddingX={3}>
                <Button onClick={handleLogin} variant='outlined'>Log in</Button>
                <Button onClick={() => {
                    dispatch(handleLoginModalState(false))
                    navigate('/')
                }} variant='outlined'>Anulo</Button>
            </Box>
        </Box>
    </Modal>
  )
}

export default Login