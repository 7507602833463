import React, { useState } from 'react'
import Blloqet from '../assets/Blloqet'
import { Box, Collapse, IconButton, Slide, Slider } from '@mui/material'
import { Menu } from '@mui/icons-material'

const Hom = () => {
  const [scaleValue, setScaleValue] = useState(1.0)
  const [show, setShow] = useState(false)
  return (
    <div>
        <Blloqet scale={scaleValue}/>
        <IconButton
          onClick={() => {
            setShow(!show)
          }}
          sx={{
            position: 'absolute',
            right: 2,
            top: 2,
            color: 'white',
            backgroundColor: 'black'
          }}
          >
            <Menu/>
          </IconButton>
        {/* <Slide in={show} >
        <Box position={'absolute'} bottom={0} left={0} sx={{backgroundColor: 'red'}} p={2} display={'flex'} justifyContent={'center'} width={'100%'}>
          <Box width={150}> 
          <Slider 
            min={1.0}
            max={6.0}
            step={0.1}
            value={scaleValue}
            onChange={(e) => {setScaleValue(e.target.value)}}
          />
          </Box>
        </Box></Slide> */}
    </div>
  )
}

export default Hom