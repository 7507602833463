import React, { useState } from 'react'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { useTheme } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import BDetailModal from '../components/BDetailModal'
import { iCache } from '../utils/cache'
import Navbar from '../components/Navbar'
const Blloqet = ({scale}) => {
  const [buildingNumberSelected, setBuildingNumberSelected] = useState('')
  const [isDragging, setIsDragging] = useState(false)
  const theme = useTheme();
  const isSmallDev = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate()
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    path: '',
    anchorEl: null,
    img: null,
    item: null,
  })
  const paths = [
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/d3-1.jpg', building: 'd3', points: "1657.3,775.1 1768.3,722.1 1754.3,716.3 1757.6,685.2 1812.6,659.1 1793.2,651.7 1846.6,627.7 1698,576.6 1600.2,615.7 1597.1,668.3 1500.4,710.4 	"
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/d2-1.jpg', building: 'd2', points: "1915.7,650.5 1761,598.3 1844.9,562.8 1850.2,514.8 1986.2,460.2 2129.1,499.1 2038.9,540.5 2057.7,546.3 2010.5,569.3 2007.4,596.5 2021.4,601.3"
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/d1-1.jpg', building: 'd1', points: '2197.1,518.2 2049.4,477.4 2079.6,463.7 2086.1,419.1 2163,387.8 2300.9,422.2 2277.4,433.1 2294.4,437.9 2256.1,455.2 2252.4,482.5 2265.6,486.2'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/c4-1.jpg', building: 'c4', points: '921.8,578.6 1035.7,628.7 1155.2,586.8 1103.1,565.4 1258.1,513.3 1147,473.2 1090,490.3 1059.4,478.8 996.1,498 998.9,553.1',
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/c3-1.jpg', building: 'c3', points: '1309.9,532.9 1477.1,474.6 1424.6,457.3 1573.4,407.6 1461.4,373.9 1401.5,392.9 1371.2,383.7 1320.1,399.7 1319.4,450.1 1195.2,490.6'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/c2-1.jpg', building: 'c2', points: '1624.5,423.6 1719.1,389.9 1668.7,375.6 1792,334 1683,305.8 1640.1,319.4 1609.1,311.2 1566.1,324.5 1563.2,371.7 1510.1,388.5'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/c1-1.jpg', building: 'c1', points: '1842.8,347.1 1905.8,325.4 1856.6,312.9 1951,280.9 1848.6,257.2 1831,264.6 1793,255.4 1754.9,267.1 1751.6,310.8 1729.6,317.9',
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/b2-1.jpg', building: 'b2', points: '1603,258.8 1680.3,236.7 1591.8,216.6 1592.6,200.4 1521.8,183.9 1447.6,203.7 1487.3,213 1487.8,206.7 1532,217.1 1531.2,237 1523.9,239.9'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/b1-1.jpg', building: 'b1', points: '1411.8,212.8 1489.3,192.6 1420,177.3 1421,161.7 1353.3,146.1 1276.7,163.5 1313.5,172.7 1314,166.3 1353.1,175.5 1352.6,193.9 1345.1,195.9'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/a1-1.jpg', building: 'a1', points: '655.8,741 845.2,694.6 776.8,636.9 763,640.4 760.5,613.9 725.2,584.3 637.9,603.1 614.4,582.2 436.7,619 483.7,670.6 566.4,651.7'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/a2-1.jpg', building: 'a2', points: '690,566.9 605.7,497.4 515.9,512.8 492.4,490.3 514.3,485.2 511.3,458.1 572,448.4 591.2,442.3 594.5,468.3 656.3,446.9 506.7,405.5 377,446.9 444.9,466.8 434.2,470.4 455.6,491.8 338.2,514.3 392.8,573 516.9,547.5 565.4,592.4'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/a3-1.jpg', building: 'a3', points: '916.7,354.5 816.1,391.7 806.9,388.2 806.9,363.7 753.8,381 696.6,367.2 699.4,401.2 607.5,433.4 506.7,405.5 622.3,368.3 665.7,380 695.6,369.3 696.1,365.4 748.7,348.3 756.4,350.9 813.6,331.5'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/a4-1.jpg', building: 'a4', points: '770.7,321.4 866,291.1 910.9,300.3 942.6,291.1 942.6,285 984.8,271.4 992,273.4 1035.9,259.5 1131.9,279.2 1048.8,308.9 1040.6,306.1 1040,284 998.8,297.3 944.6,287.1 945,317.7 870.9,344.3'
    },
    {
      type: 'polygon', imgUrl: 'https://banesa.flux-ks.com/objektetImg/a5-1.jpg', building: 'a5', points: '1286.9,224.9 1188.7,207.7 1171.5,214.4 1165.7,212.9 1128.8,224.9 1128.8,228.7 1096.6,239.1 1055.6,231.1 992.1,251 1088.4,270.3 1130.3,255.4 1130,226 1184.1,235.5 1218.7,223.1 1219.4,244.2 1227.2,245.5'
    },
  ]

  const builds = [
    {
      type: 'path', points: `M1846.6,627.7c0,0,69.7,23.5,69.7,24.5c0,1-1.4,19.6-1.4,19.6l14.1,5.4l2.9-1.4l2,0.5l-33.6,300l-113.9,68.6
      l-11.7-5.6l-37.6,22.6l-0.9,13.8l-115.4,69.6L1417.9,1036l0.8-32.6l-1.8-0.9v-12.1l-1.9-0.7v-8.3l2.2-1.7l0.4-27.1l-1.3-0.9v-8.2
      l2.6-2l0.4-27.2l-2-1.1l0.3-8.4l2.3-1.7l1.2-27.9l-2.7-0.9v-8.2l2.4-0.9l1.2-29.6l-2.2-1.2l0.2-8.7l2.2-1.7l1.1-28.7l-2.4-1
      l0.2-8.7l2.4-1.5l1.1-29.2l-2.4-1.4l0.4-8.9l0.6-0.7l0.4-13.4l175.8-74.7l2.3-39.7l97.6-40.3L1846.6,627.7z`, building: 'd3'
    },
    {
      type: 'polygon', building: 'd2', points: `1850.2,514.8 1846.6,551.8 1749.1,593.5 1916.3,652.2 1914.9,671.7 1929,677.2 1931.9,675.8 
      1933.9,676.3 1900.3,976.3 1979.1,928.2 1980.8,915.3 2009.6,898.2 2021.1,902.8 2171,811.7 2212.6,540.2 2209.4,539.3 
      2208.1,540.1 2194.7,536.3 2197.1,518.2 1986.2,460.2`
    },
    {
      type: 'polygon', building: 'd1', points: `2086.1,419.1 2081.2,453 2035.4,472.9 2197.1,518.2 2194.7,536.3 2208.1,540.1 2209.4,539.3 
      2212.6,540.2 2171.3,809.9 2215.2,785.4 2217.1,773.2 2237.7,761.4 2248.9,764.5 2317.8,722.9 2315.9,722 2320.9,694.9 
      2322.4,695.1 2324.3,685.6 2326.6,684.9 2327.6,677.4 2326.1,676.7 2330.4,653.8 2332.2,652.8 2333.9,645.3 2332.1,644.7 
      2336.3,621.8 2338.2,620.6 2339.6,613.5 2338.1,612.7 2342,589.3 2344.2,588.1 2345.7,580.8 2344,580.2 2348.1,556.3 2350.3,555.3 
      2352,547.6 2350.5,546.9 2354.4,523.1 2356.4,521.9 2357.8,514.3 2356.6,513.9 2360.8,489.6 2362.8,488.7 2364.1,481 2363.7,480.7 
      2365.4,469.6 2318.8,457.7 2321.9,456.5 2295.8,450.1 2300.9,422.2 2163,387.8`
    },
    {
      type: 'polygon', building: 'c4', points: `1147.7,473.2 1090,490.3 1059.3,478.3 996.1,498 998.9,553.1 921.8,578 921.8,581.4 877.6,595.7 
      877.5,616.8 880.3,617.8 880.6,621.8 852,631.5 852.8,643.8 851.9,644.2 860.8,771.9 884.3,793.9 887.3,838.7 1029.1,916.7 
      1031,968.4 1322.5,835.8 1325.9,554.9 1323.3,554 1322.6,554.4 1312.5,550.9 1312.2,531.1`,
    },
    {
      type: 'polygon', building: 'c3', points: `1195.2,489.5 1319,450.3 1320.1,400.4 1371.2,383.7 1403.2,392.7 1461.4,373.9 1625.6,423.2 
      1624.1,440.3 1635.6,442.7 1637.2,442.4 1638.5,442.8 1629.3,604 1601.4,616.2 1597.7,668.2 1422.3,744.7 1421.9,753.7 
      1424.3,755.1 1423.2,784.2 1420.9,785.7 1420.7,791.8 1322.5,835.8 1325.9,554.9 1323.3,554 1322.6,554.4 1312.5,550.9 
      1312.2,531.1`
    },
    {
      type: 'polygon', building: 'c2', points: `1683,305.8 1842.5,347.8 1841.6,362.6 1852.5,365.2 1853.4,365.2 1855,365.6 1836.6,566.2 1761,597.7 
      1699,575.9 1629.3,604 1638.5,442.8 1637.2,442.4 1635.6,442.7 1624.1,440.3 1625.6,423.2 1510.1,388.5 1563.2,371.7 1566.1,324.5 
      1609.1,311.2 1640.1,319.4`
    },
    {
      type: 'polygon', building: 'c1', points: `1728.4,317.7 1751.6,310.8 1754.9,267.1 1793,255.4 1831,264.6 1848.6,257.2 1951,280.4 1948.7,306.7 
      1952.7,307.5 1952.7,310 1996.8,322.1 1996.6,331.6 1996.8,331.6 1996.6,338.1 1994.4,339 1993.7,346.5 2016.1,352.3 2015.3,362.1 
      2015.9,361.9 2003.2,464.6 1986.2,460.2 1850.2,514.8 1847.4,551.2 1846.2,550.6 1844.8,562.7 1836.6,566.2 1855,365.6 
      1853.1,365.2 1852.3,365.1 1841.6,362.6 1842.5,347.8`
    },
    {
      type: 'polygon', building: 'b2', points: `1521.8,183.9 1411.8,212.8 1411.1,225.9 1401.2,227.7 1397.3,391.7 1402.2,392.7 1461.4,373.9 
      1510.1,388.5 1563.2,371.7 1566.1,324.5 1609.1,311.2 1640.1,319.4 1683,305.8 1726.5,317.1 1728.4,291.3 1728,290.9 1728.2,281.8 
      1708.6,277.3 1708.8,271.1 1710.5,270.3 1710.7,264.3 1710.2,264 1710.7,255 1679.7,247.8 1680.3,236.5 1592,216.5 1592.6,200.4`
    },
    {
      type: 'polygon', building: 'b1', points: `1353.3,146.1 1276.7,163.5 1276.7,185.2 1271.2,186.3 1271.2,190.6 1242.6,197.3 1242.6,206 1242,206 
      1242.2,211.7 1243.3,211.7 1243.3,217.3 1286.9,225.3 1286.9,240.5 1285.3,241.2 1285.3,275.8 1306.2,279.8 1306.2,289.1 
      1306.6,289.1 1305.8,376.4 1306.6,377.1 1313.5,374.8 1341.3,382.8 1341.6,387.3 1352,389.8 1371.2,383.7 1397.3,391.7 
      1401.2,227.7 1411.1,225.9 1411.8,212.8 1489.3,192.6 1420.1,177.3 1421,161.7`
    },
    {
      type: 'polygon', building:'a1' , points: `884.3,793.9 897.6,995.1 870,1004 872.8,1039.5 631.1,1146.6 482,948.3 436.7,619 690.7,567.1 
      692.3,585.5 707.7,581.8 710.1,583.7 710.3,587.3 726.1,584 761.7,613.5 763.5,641.1 776.8,636.9 845.2,694.6 846.8,718.8 
      845.2,718.8 847.3,754.1 851.5,752.6 882.5,779.7 882.9,791.7`
    },
    {
      type: 'polygon', building: 'a2', points: `678.5,557.5 669.8,466.8 668,466 666.9,466.7 657.2,463.8 655.9,446.8 506.7,405.5 377,446.9 
      382.3,481.5 305.5,507.7 307.1,518.6 306.4,519.1 307.5,526.6 309.5,528.9 313.3,552.9 311.7,553.1 313,560.9 315.3,563.6 
      318.3,595.5 320.4,598.3 323.9,631 326,633.9 329.5,665.7 331.8,668.5 334.6,700.6 337.3,703.6 340.2,733.7 342.9,737.7 
      344.7,751.2 343.7,751.6 345.6,762.4 344.5,762.4 345.5,767.5 482,948.3 436.7,619 690.7,567.1`
    },
    {
      type: 'polygon', building: 'a3', points: `917.7,354.5 918.3,370.6 927.5,372.9 928.6,372.3 930.4,372.9 941.1,572.3 921.8,578 921.8,592.9 
      877.5,609.4 877.5,616.8 880.3,617.8 881.3,633.4 851.9,644.2 852.9,658.6 834.7,667.3 825.8,664.6 815.9,669.8 776.8,636.9 
      763.5,641.1 761.7,613.5 726.1,584 710.3,587.3 710.1,583.7 707.7,581.8 692.3,585.5 690.7,567.1 678.5,557.5 669.8,466.8 668,466 
      666.9,466.7 657.2,463.8 655.9,446.8 506.7,405.5 622.3,368.3 665.8,379.8 696.7,368.4 696.1,365.4 748.7,348.3 756.4,351.1 
      813.6,331.5`
    },
    {
      type: 'polygon', building: 'a4', points: `761.5,349 760.6,334.9 764.3,333.7 765.8,333.7 765.8,335.1 771.2,335.7 770.7,321.4 866,291.1 
      910.9,300.3 942.6,289.6 942.6,285.5 985.9,271.7 992,273.4 1034.6,259.9 1132.6,278.9 1132.6,294.1 1142.4,295.7 1143.6,295.4 
      1144.3,295.6 1147.7,473.2 1090,490.3 1059.3,478.3 996.1,498 998.9,553.1 941.1,572.3 930.4,372.9 928.6,372.3 927.5,372.9 
      918.3,370.6 917.7,354.5 813.6,331.5`
    },
    {
      type: 'polygon', building: 'a5', points: `980.9,273.3 980.9,264.2 983.7,262.9 985.4,263.2 985.4,264 991.2,264.8 990.9,251.3 1055.6,231.1 
      1096.6,239.1 1128.5,228.3 1128.8,224.9 1165.7,212.9 1171.9,214.2 1188.7,207.7 1286.9,225.3 1286.9,240.5 1285.3,241.2 
      1285.3,275.8 1306.2,279.8 1306.2,289.1 1306.6,289.1 1306.6,292.9 1305.3,425.4 1304.6,448.7 1254.2,471.4 1195.2,489.5 
      1147.7,473.2 1144.3,295.6 1143.6,295.4 1142.4,295.7 1132.6,294.1 1132.6,278.9 1035.3,259.5 992,273.4 985.9,271.7`
    },
  ]
  const handleClick = (e, click, imgUrl) => {
   if(!isDragging){
     setModalState({
      ...modalState,
      anchorEl: e.currentTarget,
      open: true,
      id: click,
      img: imgUrl,
     })
    
   }
  }
  const handleOnMouseLeave = () => {
    setModalState({
      ...modalState,
      anchorEl: null,
      open: false,
     })
  }
  React.useEffect(() => {
    const data = []
    paths.forEach((item) => {
      data.push(item.imgUrl)
    })
    iCache(data)
  }, [])
  return (
    <>
    <Navbar />
      {
        isSmallDev ? <div style={{ width: "100%", height: "100vh", overflowY: 'hidden'}}>
           <svg
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              xmlSpace="preserve"
              width={"350%"}
              viewBox="0 0 2501 1406.81"
              
            >
              <image
                width="7680"
                height="4320"
                transform="matrix(0.3255 0 0 0.3255 1.0213 -3.125)"
                xlinkHref="https://banesa.flux-ks.com/objektetImg/blloqet.jpg"
              />
              {builds.map((item) => {
                if(item.type === 'path') {
                  return <path className={`b b${item.building}-${buildingNumberSelected}`} d={item.points}/>
                }
                if(item.type === 'polygon'){
                  return <polygon className={`b b${item.building}-${buildingNumberSelected}`} points={item.points} />
                }
                else {
                  return
                }
              })}
              {paths.map((item) => {
                if(item.type === 'polygon') {
                  return (
                    <polygon
                      onMouseLeave={() => {
                        setBuildingNumberSelected(0);
                      }}
                      onMouseEnter={() => {
                        setBuildingNumberSelected(item.building);
                      }}
                      className={'st0'}
                      points={item.points}
                      onMouseOver={(e) => {
                        handleClick(e, item.building, item.imgUrl);
                      }}
                      onClick={() => { navigate(`/objektet/${item.building}`)}}
                    />
                  );
                }
                
              })}
              {/* <circle className={`cls-1 cls-s1-${buildingNumberSelected}`} cx="1000.45" cy="380.67" r={buildingNumberSelected === 1 ? '50.21' : "17.31"} style={{ transition: 'r 0.1s ease-in-out, fill 0.5s ease-in-out', pointerEvents: 'none' }}/>
        <circle className={`cls-1 cls-s3-${buildingNumberSelected}`} cx="1450.66" cy="261.82" r={buildingNumberSelected === 3 ? '50.21' : "17.31"} style={{ transition: 'r 0.1s ease-in-out, fill 0.5s ease-in-out', pointerEvents: 'none' }}/>
        <circle className={`cls-1 cls-s4-${buildingNumberSelected}`} cx="1970.31" cy="680.45" r={buildingNumberSelected === 4 ? '50.21' : "17.31"} style={{ transition: 'r 0.1s ease-in-out, fill 0.5s ease-in-out', pointerEvents: 'none' }}/> */}
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a3-${buildingNumberSelected}`} cx="748.6" cy="361.9" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a4-${buildingNumberSelected}`} cx="992.1" cy="288.3" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a5-${buildingNumberSelected}`} cx="1183.2" cy="227" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-b1-${buildingNumberSelected}`} cx="1365.6" cy="168.8" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-b2-${buildingNumberSelected}`} cx="1531.1" cy="207.7" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c1-${buildingNumberSelected}`} cx="1813" cy="268.9" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c2-${buildingNumberSelected}`} cx="1638.5" cy="331.5" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c3-${buildingNumberSelected}`} cx="1383" cy="405.5" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c4-${buildingNumberSelected}`} cx="1068.1" cy="508.2" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a1-${buildingNumberSelected}`} cx="690" cy="610.8" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a2-${buildingNumberSelected}`} cx="526.1" cy="445.4" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-d3-${buildingNumberSelected}`} cx="1737.1" cy="657.8" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-d2-${buildingNumberSelected}`} cx="1992.8" cy="543.3" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-d1-${buildingNumberSelected}`} cx="2236.8" cy="433.4" r="12"/>
            </svg>
             </div> : <div style={{ width: "100%", height: "100vh" }}>
        <TransformWrapper
          initialPositionX={0}
          initialPositionY={0}
          initialScale={1.0}
          disabled
          
        >
          <TransformComponent
            wrapperStyle={{ width: "100%" }}
            contentStyle={{ width: "100%" }}
          >
            <svg
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              xmlSpace="preserve"
              width={"100%"}
              height={"auto"}
              viewBox="0 0 2501 1406.81"
              
            >
              <image
                width="7680"
                height="4320"
                transform="matrix(0.3255 0 0 0.3255 1.0213 -3.125)"
                xlinkHref="https://banesa.flux-ks.com/objektetImg/blloqet.jpg"
              />
              
              {builds.map((item) => {
                if(item.type === 'path') {
                  return <path className={`b b${item.building}-${buildingNumberSelected}`} d={item.points}/>
                }
                if(item.type === 'polygon'){
                  return <polygon className={`b b${item.building}-${buildingNumberSelected}`} points={item.points} />
                }
                else {
                  return
                }
              })}
              {paths.map((item) => {
                if(item.type === 'polygon') {
                  return (
                    <polygon
                      onMouseLeave={() => {
                        setBuildingNumberSelected(0);
                        handleOnMouseLeave()
                      }}
                      onMouseEnter={() => {
                        setBuildingNumberSelected(item.building);
                      }}
                      className={'st0'}
                      points={item.points}
                      onMouseOver={(e) => {
                        handleClick(e, item.building, item.imgUrl);
                      }}
                      onClick={() => { navigate(`/objektet/${item.building}`)}}
                    />
                  );
                }
                
              })}
               <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a3-${buildingNumberSelected}`} cx="748.6" cy="361.9" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a4-${buildingNumberSelected}`} cx="992.1" cy="288.3" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a5-${buildingNumberSelected}`} cx="1183.2" cy="227" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-b1-${buildingNumberSelected}`} cx="1365.6" cy="168.8" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-b2-${buildingNumberSelected}`} cx="1531.1" cy="207.7" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c1-${buildingNumberSelected}`} cx="1813" cy="268.9" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c2-${buildingNumberSelected}`} cx="1638.5" cy="331.5" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c3-${buildingNumberSelected}`} cx="1383" cy="405.5" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-c4-${buildingNumberSelected}`} cx="1068.1" cy="508.2" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a1-${buildingNumberSelected}`} cx="690" cy="610.8" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-a2-${buildingNumberSelected}`} cx="526.1" cy="445.4" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-d3-${buildingNumberSelected}`} cx="1737.1" cy="657.8" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-d2-${buildingNumberSelected}`} cx="1992.8" cy="543.3" r="12"/>
              <circle style={{
                  transition: "opacity 0.3s ease-in-out, fill 0.5s ease-in-out",
                  pointerEvents: "none",
                }}
                 className={`cls-1 cls-d1-${buildingNumberSelected}`} cx="2236.8" cy="433.4" r="12"/>
              {/* <circle className={`cls-1 cls-s1-${buildingNumberSelected}`} cx="1000.45" cy="380.67" r={buildingNumberSelected === 1 ? '50.21' : "17.31"} style={{ transition: 'r 0.1s ease-in-out, fill 0.5s ease-in-out', pointerEvents: 'none' }}/>
        <circle className={`cls-1 cls-s3-${buildingNumberSelected}`} cx="1450.66" cy="261.82" r={buildingNumberSelected === 3 ? '50.21' : "17.31"} style={{ transition: 'r 0.1s ease-in-out, fill 0.5s ease-in-out', pointerEvents: 'none' }}/>
        <circle className={`cls-1 cls-s4-${buildingNumberSelected}`} cx="1970.31" cy="680.45" r={buildingNumberSelected === 4 ? '50.21' : "17.31"} style={{ transition: 'r 0.1s ease-in-out, fill 0.5s ease-in-out', pointerEvents: 'none' }}/> */}
            </svg>
          </TransformComponent>
        </TransformWrapper>
      </div>
      }
      {modalState.open ? <BDetailModal setState={setModalState} modalState={modalState}/> : null}
    </>
  );
}

export default Blloqet