import { Button } from '@mui/material'
import axios from 'axios';
import React from 'react'
import {getDataToSave} from '../components/convert'
import { BASE_URL } from '../utils/consts'
import {tokenConfig} from '../features/AuthSlice'

const A12Page = () => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const apartmentData = getDataToSave();
        apartmentData.forEach((item) => {
          const formData = new FormData();
          formData.append("rooms", item.rooms);
          formData.append("isSold", false);
          formData.append("floor", item.floor);
          formData.append("square", item.square);
          formData.append("name", item.name);
          formData.append("apartmentNumber", item.apartmentNumber);
          formData.append("style", "");
          formData.append("className", "");
          formData.append("imageData", null);
          formData.append("path", item.path);
          formData.append("apartmentId", item.apartmentNumber);
          formData.append("balconySquare", item.balconySquare);
          formData.append("buildingNr", 0);
          const response = axios
            .post(`${BASE_URL}/api/apartment/createall`, formData, tokenConfig())
            .then((res) => {
              if (response.status === 200) {
                console.log("User created successfully");
              }
            })
            .catch((error) => {
              console.error("An error occurred", error);
            });
        });
      };
  return (
    <div><Button variant='contained' onClick={handleSubmit}>Add to Server</Button></div>
  )
}

export default A12Page