import { configureStore } from '@reduxjs/toolkit'
import SmallDevFilterModal from '../features/SmallDevFilterModal'
import ApartmentEditSlice from '../features/ApartmentEditSlice'
import ApartmentSlice from '../features/Apartment/ApartmentSlice'
import ApartmentDetailSlice from '../features/ApartmentDetailSlice'
import AuthSlice from '../features/AuthSlice'
import WishlistSlice from '../features/WishlistSlice'
import ConfigSlice from '../features/ConfigSlice'

export const store = configureStore({
  reducer: {
    SmallDevFilterModalSlice: SmallDevFilterModal,
    ApartmentEditSlice: ApartmentEditSlice,
    ApartmentSlice: ApartmentSlice,
    ApartmentDetailSlice: ApartmentDetailSlice,
    AuthSlice: AuthSlice,
    WishlistSlice: WishlistSlice,
    ConfigSlice: ConfigSlice,
  },
})