import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

const ApartmentData = ({title, value}) => {
  const theme = useTheme()
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} marginRight={isSmallDev ? 2 : 0}>
        <Typography>{title}</Typography>
        <Typography fontWeight={'bold'}>{value}</Typography>
    </Box>
  )
}

export default ApartmentData