import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { BASE_URL } from "../utils/consts"
import { tokenConfig } from "./AuthSlice"

const url = `${BASE_URL}/api/configs`
const initialState = {
    contactPhoneNumber: '',
    contactEmail: '',
    status: 'idle',
    msg: '',
}

const getConfigData = createAsyncThunk(
    'ConfigSlice',
    async(o, { rejectWithValue }) => {
        try {
            const res = await axios.get(`${url}/get`)
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const ConfigSlice = createSlice({
    name: 'ConfigSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getConfigData.pending , (state, action) => {
            state.status = 'get_config_pending'
        })
        builder.addCase(getConfigData.fulfilled , (state, action) => {
            state.status = 'get_config_success'
            state.contactEmail = action.payload.contactEmail
            state.contactPhoneNumber = action.payload.contactPhoneNumber
        })
        builder.addCase(getConfigData.rejected , (state, action) => {
            state.status = 'get_config_rejected'
            state.msg = action.payload
        })
    }
})
export const getContactPhoneNumber = (state) => state.ConfigSlice.contactPhoneNumber;
export const getContactEmail = (state) => state.ConfigSlice.contactEmail;
export default ConfigSlice.reducer