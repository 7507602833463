import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    apartmentEdit: { open: false, data: {} },
}

const ApartmentEditSlice = createSlice({
    name: "ApartmentEditSlice",
    initialState,
    reducers: {
        setApartmentEditModalState(state, action) {
            state.apartmentEdit.open = action.payload
        },
        setApartmentEditModalData(state, action){
            state.apartmentEdit.data = action.payload
        }
    }
})
export const {

    setApartmentEditModalState,
    setApartmentEditModalData,

} = ApartmentEditSlice.actions
export const getApartmentEdit = (state) => state.ApartmentEditSlice.apartmentEdit
export default ApartmentEditSlice.reducer