import { Close, Tune } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
  createTheme,
  Button,
} from "@mui/material";
import React from "react";
import FilterSpeedDial from "./FilterSpeedDial";
import { useDispatch, useSelector } from "react-redux";
import { getSmallDevFilterModalState } from "../features/SmallDevFilterModal";
import {
  getApartmentsSVG,
  getFilterData,
  getShowSmallDevFilter,
  setFilterFloor,
  setFilterRoom,
  setFilterSquare,
  showSmallDevFilter,
} from "../features/Apartment/ApartmentSlice";
import { ThemeProvider } from "styled-components";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Montserrat"',
    ].join(","),
  },
});

const CustomSlider = styled(Slider)`
  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    background-color: #f6cf3e;
  }
  .MuiSlider-track {
    border: 1px solid #ffffff;
  }
`;

const Filters = () => {
  const smallDevFilterModalState = useSelector(getSmallDevFilterModalState);
  const filterData = useSelector(getFilterData);
  const themee = useTheme();
  const isSmallDev = useMediaQuery(themee.breakpoints.down("md"));
  const showSmallDevState = useSelector(getShowSmallDevFilter);
  const apartmentData = useSelector(getApartmentsSVG)

  const dispatch = useDispatch();
  function valuetext(value) {
    return `${value}°C`;
  }
  const handleChangeFloor = (event, newValue) => {
    dispatch(setFilterFloor(newValue));
  };
  const handleChangeSquare = (event, newValue) => {
    dispatch(setFilterSquare(newValue));
  };
  const roomBoxButtonStyle = {
    marginTop: 1,
    userSelect: "none",
    ":hover": {
      backgroundColor: "#6f633a",
      cursor: "pointer",
      color: 'white'
    },
    ":active": {
      backgroundColor: "#fff",
      color: "black",
    },
  };
  const filterLabelStyle = {
    fontSize: isSmallDev ? "24px" : "1.8rem",
    fontFamily: "Montserrat",
    color: '#FFE99A'
  };
  const infoTextStyle = {
    fontSize: isSmallDev ? "14px" : "1.5rem",
    fontFamily: "Montserrat",
    color: '#FFF'
  };
  const filterStyleSmallDev = () => {
    if (isSmallDev) {
      return {
        position: "absolute",
        top: 0,
        backgroundColor: "rgba(0, 0, 0, 0.85);",
        padding: 2,
        paddingY: 3,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else return {};
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={filterStyleSmallDev}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={isSmallDev ? 0 : 8}
          padding={isSmallDev ? 0 : 4}
          paddingY={isSmallDev ? 0 : 5}
          
          marginX={5}
          mt={1}
          mb={2}
          position={isSmallDev ? "unset" : "relative"}
        >
          <Box
            display={"flex"}
            textAlign={isSmallDev ? "left" : ""}
            flexDirection={"column"}
            width={"calc(100% - 20px)"}
            marginBottom={3}
          >
            <Typography
              sx={{...filterLabelStyle}}
              variant={"h6"}
              color={"red"}
              fontFamily={"Montserrat"}
              
            >
              TIPI
            </Typography>
            {showSmallDevState && isSmallDev === true ? (
              <IconButton
                onClick={() => {
                  dispatch(showSmallDevFilter(false));
                }}
                size="medium"
                sx={{
                  position: "absolute",
                  top: isSmallDev ? 25 : -40,
                  right: isSmallDev ? 65 : 25,
                  color: "#FFE99A",
                  borderRadius: "50%",
                  border: "2px solid #FFE99A", // Change the color here
                  p: 1, // Adjust padding
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Close fontSize="medium" />
              </IconButton>
            ) : null}
            <Box
              display={"flex"}
              gap={3}
              justifyContent={isSmallDev ? "space-between" : ""}
            >
              <Box display={"flex"} gap={3} flexDirection={'column'}>
                <Box
                  width={152}
                  height={55}
                  border={'3px solid #FFE99A;'}
                  bgcolor={
                    filterData.rooms.includes("1+1")
                      ? "#FFE99A"
                      : ""
                  }
                  color={
                    filterData.rooms.includes("1+1")
                      ? "black"
                      : "white"
                  }
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={'10px'}
                  sx={{...roomBoxButtonStyle }}
                  onClick={() => {
                    dispatch(setFilterRoom("1+1"));
                  }}
                >
                  <Typography sx={{fontFamily: 'Montserrat', fontSize: '24px'}}>1+1</Typography>
                </Box>
                <Box
                  width={152}
                  height={55}
                  border={'3px solid #FFE99A;'}
                  borderRadius={'10px'}
                  bgcolor={
                    filterData.rooms.includes("2+1")
                      ? "#FFE99A"
                      : ""
                  }
                  color={
                    filterData.rooms.includes("2+1")
                      ? "black"
                      : "white"
                  }
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={roomBoxButtonStyle}
                  onClick={() => {
                    dispatch(setFilterRoom("2+1"));
                  }}
                >
                  <Typography sx={{fontFamily: 'Montserrat', fontSize: '24px'}}>2+1</Typography>
                </Box>
              </Box>
              <Box display={"flex"} gap={3} flexDirection={'column'} marginBottom={3}>
                <Box
                  width={152}
                  height={55}
                  border={'3px solid #FFE99A;'}
                  borderRadius={'10px'}
                  bgcolor={
                    filterData.rooms.includes("3+1")
                      ? "#FFE99A"
                      : ""
                  }
                  color={
                    filterData.rooms.includes("3+1")
                      ? "black"
                      : "white"
                  }
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={roomBoxButtonStyle}
                  onClick={() => {
                    dispatch(setFilterRoom("3+1"));
                  }}
                >
                  <Typography sx={{fontFamily: 'Montserrat', fontSize: '24px'}}>3+1</Typography>
                </Box>
                <Box
                  width={152}
                  height={55}
                  border={'3px solid #FFE99A;'}
                  borderRadius={'10px'}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  bgcolor={
                    filterData.rooms.includes("4+1")
                      ? "#FFE99A"
                      : ""
                  }
                  color={
                    filterData.rooms.includes("4+1")
                      ? "black"
                      : "white"
                  }
                  sx={roomBoxButtonStyle}
                  onClick={() => {
                    dispatch(setFilterRoom("4+1"));
                  }}
                >
                  <Typography sx={{fontFamily: 'Montserrat', fontSize: '24px'}}>4+1</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          
          <Box
            textAlign={isSmallDev ? "start" : "start"}
            flexDirection={"column"}
            marginTop={isSmallDev ? 2 : 1}
            width={"calc(100% - 20px)"}
            marginBottom={5}
          >
            <Typography
              fontFamily={"Montserrat"}
              sx={{...filterLabelStyle}}
              variant={"h5"}
              color={"white"}
            >
              KATI
            </Typography>
            <Box
              display={"flex"}
              paddingLeft={isSmallDev ? 1 : 1}
              justifyContent={"center"}
              width={isSmallDev ? "calc(100% - 20px)" : "100%"}
            >
              <CustomSlider
                getAriaLabel={() => "Temperature range"}
                step={1}
                direction="row"
                min={1}
                max={apartmentData[0]?.maxFloor ? apartmentData[0].maxFloor : 7}
                sx={{ color: "#f6cf3e" }}
                value={[filterData.floor.startVal, filterData.floor.endVal]}
                onChange={handleChangeFloor}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Box>
            <Box
              display={"flex"}
              paddingLeft={isSmallDev ? 1 : 1}
              justifyContent={"space-between"}
              color={"white"}
              width={isSmallDev ? "calc(100% - 20px)" : "100%"}
            >
              <Typography sx={infoTextStyle} variant="body1">{filterData.floor.startVal}</Typography>
              <Typography sx={infoTextStyle} variant="body1">{filterData.floor.endVal}</Typography>
            </Box>
          </Box>
          <Box
            width={"calc(100% - 20px)"}
            display={"flex"}
            flexDirection={"column"}
            textAlign={isSmallDev ? "start" : ""}
            
          >
            <Typography
              fontFamily={"Montserrat"}
              sx={{...filterLabelStyle}}
              variant={"h5"}
              color={"white"}
              marginBottom={1}
            >
              SIPERFAQJA
            </Typography>
            <Box
              paddingLeft={isSmallDev ? 1 : 1}
              width={isSmallDev ? "calc(100% - 20px)" : "100%"}
            >
              <CustomSlider
                step={1}
                sx={{ color: "#f6cf3e", border: "-25px solid #f6cf3e" }}
                getAriaLabel={() => "Temperature range"}
                direction="row"
                min={40}
                max={300}
                value={[filterData.square.startVal, filterData.square.endVal]}
                onChange={handleChangeSquare}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Box>
            <Box
              display={"flex"}
              paddingLeft={isSmallDev ? 1 : 1}
              justifyContent={"space-between"}
              color={"white"}
              width={isSmallDev ? "calc(100% - 20px)" : "100%"}
            >
              <Typography sx={infoTextStyle} variant="body1">
                {filterData.square.startVal}m2
              </Typography>
              <Typography sx={infoTextStyle} variant="body1">
                {filterData.square.endVal}m2
              </Typography>
            </Box>
            {isSmallDev ? <Button
            onClick={() => {
              dispatch(showSmallDevFilter(false));
            }}
            sx={{mt: 2, backgroundColor: '#FFE99A', color: 'black', fontSize: '14px', padding: '19px 40px', borderRadius: '7px', ':active' : {
              backgroundColor: '#a17f00',
              color: 'white'
            },
            ':hover' : {
              backgroundColor: '#a17f00',
              color: 'white'
            }
            
            }} startIcon={<Tune sx={{fontSize: '0.8rem'}}/>} variant='contained'>Filtro</Button> : null}
          </Box>
        </Box>
        {smallDevFilterModalState.open ? <FilterSpeedDial /> : null}
        
      </Box>
    </ThemeProvider>
  );
};

export default Filters;
