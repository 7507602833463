import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    smallDevFilterModalState: { open: false, data: {} },
};

const SmallDevFilterModalSlice = createSlice(
    {
        name: 'SmallDevFilterModalSlice',
        initialState,
        reducers: {
            setSmallDevFilterModalState(state, action) {
                state.smallDevFilterModalState.open = action.payload
            }
        }
    }
)

export const {
    setSmallDevFilterModalState,
} = SmallDevFilterModalSlice.actions;

export const getSmallDevFilterModalState = (state) => state.SmallDevFilterModalSlice.smallDevFilterModalState

export default SmallDevFilterModalSlice.reducer;