import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWishListModalState, handleWishListModalState } from '../features/WishlistSlice'
import { Box, Grid, IconButton, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import WishListItemCard from './WishListItemCard';
import { Close } from '@mui/icons-material';



const WishListModal = () => {
    const state = useSelector(getWishListModalState)
    const wishListItems = JSON.parse(localStorage.getItem('wish')) || [] ;
    const dispatch = useDispatch()
    const themee = useTheme();
    const isSmallDev = useMediaQuery(themee.breakpoints.down('md'))
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        height: isSmallDev ? '100%' : '100%',
        transform: "translate(-50%, -50%)",
        width: isSmallDev ? '100%' : '100%',
        bgcolor: isSmallDev ? 'rgba(0, 0, 0, 0.68)' : "background.paper",
        color: isSmallDev ? 'white' : 'black',
        border: "2px solid #000",
        boxShadow: 40,
        textAlign: 'center',
        borderRadius: 5,
        overflowY: 'auto',
        overflowX: 'hidden',
        p: 2,
      };
  return (
    <Modal open={state}>
        <Box sx={{...style}}>
            <Typography variant='h4' fontWeight={'500'} textAlign={'center'}>Wish List</Typography>
            <IconButton 
            onClick={() => {
                dispatch(handleWishListModalState(false))
            }}
            sx={{
                position: 'absolute',
                top: 10,
                right: 15,
                color: isSmallDev ? 'white' : ''
            }}>
                <Close />
            </IconButton>
            <Grid container p={isSmallDev ? 1 : 5} spacing={2} mt={2} width={'100%'}>
                { wishListItems?.length < 1 ? 
                <Box width={'100%'}><Typography fontStyle={'oblique'} textAlign={'center'}>Nuk ka te dhena!</Typography></Box> : null}
                {
                    wishListItems.map((item, index) => {
                        
                        if(index < 0){
                            return <Typography>Nuk ka te dhena!</Typography>
                        }
                        else {
                            return  <Grid item sm={12} md={12} width={isSmallDev ? '100%' : ''} >
                            <WishListItemCard id={item}/>
                        </Grid>
                        }
                    })
                }
            </Grid>
        </Box>
    </Modal>
  )
}

export default WishListModal