import { Box, Collapse, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getAllApartments, getAllApartmentsById, getApartmentsSVGByBuilding } from '../features/Apartment/ApartmentAPI';
import { getADetailModalState, setADetailModalState, setApartmentDetailData } from '../features/ApartmentDetailSlice';
import { getApartmentEdit } from '../features/ApartmentEditSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getApartmentsData, getApartmentsSVG, getFilterData, getShowSmallDevFilter, showSmallDevFilter } from '../features/Apartment/ApartmentSlice';
import { isAuthorized } from '../features/AuthSlice';
import Filters from '../components/Filters';
import { ArrowBack, ArrowForward, Tune } from '@mui/icons-material';
import ADetailsModal from '../components/ADetailsModal';
import ADetailModal1 from '../components/ADetailModal1';
import { useParams } from 'react-router-dom';

const Test = ({buildId}) => {
    const theme = useTheme();
    const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
  const isADetailModalOpen = useSelector(getADetailModalState)
  const apartmentEdit = useSelector(getApartmentEdit)
  const dispatch = useDispatch();
  const filter = useSelector(getFilterData)
  const pathFromDb = useSelector(getApartmentsData)
  const apartmentSVGs = useSelector(getApartmentsSVG)
  const showBox = useSelector(getShowSmallDevFilter);
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    path: '',
    anchorEl: null,
    img: null,
  })
  const [menu, setMenu] = useState({
    open: false,
    anchorEl: null,
    data: null
  })
  const [currentIdx, setCurrentIdx] = useState(0);
  const handlePrev = () => {
    setCurrentIdx((prevIdx) => Math.max(prevIdx - 1, 0));
    if(currentIdx === 0) {
      setCurrentIdx((prevIdx) => Math.min(prevIdx + 1, apartmentSVGs.length - 1));
    }
  };
  const handleNext = () => {
    setCurrentIdx((prevIdx) => Math.min(prevIdx + 1, apartmentSVGs.length - 1));
    if(currentIdx === apartmentSVGs.length - 1){
      setCurrentIdx((prevIdx) => Math.max(prevIdx - 1, 0));
    }
  };
 
  const handleContextMenu = ({e, item}) => {
    e.preventDefault()
    if(isAuthorized()){
      setMenu(
        {
          open: true,
          anchorEl: e.currentTarget,
          data: item,
          id: buildId
        }
      )
    }
  }
  const handlePathClick = (item) => {
    
    dispatch(setApartmentDetailData(item))
    //client modal
    dispatch(setADetailModalState(true))
    
  }
  const handlePathEnter= ({ e, item}) => {
    // dispatch(setApartmentDetailData(item))
    // setModalState({
    //   open: true,
    //   title: item.id,
    //   path: undefined,
    //   anchorEl: e.currentTarget,
    //   square: item.square,
    //   floor: item.floor,
    //   rooms: item.rooms,
    //   img: item.imageData
    // })
  }
  const handlePathLeave = ({ e, item}) => {
    
    setModalState({
      open: false,
      title: item.id,
      path: undefined,
      anchorEl: null,
      square: '',
      floor: '',
      rooms: '',
      img: null,
    })

  }
  useEffect(() => {
    dispatch(getApartmentsSVGByBuilding(buildId))
    dispatch(getAllApartmentsById(buildId))
    
  }, [buildId])
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      // Calculate the midpoint of the container
      const middle = container.scrollWidth / 4;
      container.scrollLeft = middle;
    }
  }, []);
  
  return (
    <Box width={'100%'} height={'100vh'} sx={{backgroundColor: 'black', overflow: 'hidden', position: 'relative'}}> 
      <Typography variant='h5' sx={{textAlign: 'center', color: 'white', pt: 4, fontFamily: 'Montserrat', fontWeight: 'bold'}}>OBJEKTI: {buildId.toUpperCase()}</Typography>
     <Box width={'100vw'} height={'calc(100vh - 187px)'} sx={{overflowX: 'auto', overflowY: 'hidden'}} ref={containerRef}>
     {showBox ? null : <>
      <IconButton onClick={() => {
        handlePrev()
      }}  sx={{position: 'absolute', bottom: isSmallDev ? 'calc(50% - 20px)' : '50%', left: 10, color: 'black'}}>
        <ArrowBack fontSize={isSmallDev ? 'small' : 'large'} sx={{backgroundColor: '#f6cf3e', borderRadius: '50px', p: 1}}/>
      </IconButton>
      <IconButton onClick={() => {
        handleNext()
      }} sx={{position: 'absolute', bottom: isSmallDev ? 'calc(50% - 20px)' : '50%', right: 10, color: 'black'}}>
        <ArrowForward fontSize={isSmallDev ? 'small' : 'large'} sx={{backgroundColor: '#f6cf3e', borderRadius: '50px', p: 1}}/>
      </IconButton></>}
      {apartmentSVGs.map((item, idx) => (
        <div  style={{width: '100%', height: currentIdx === idx ? '100%' : 0 , opacity: currentIdx === idx ? 1 : 0,
                      transition: "opacity 0.3s ease-in-out",}}>
          <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px" 
          y="0px"
          viewBox={item.viewBoxStyle}
          xmlSpace="preserve"
          width={"200%"} 
          height={"100%"}
        >
          <image
            style={{ overflow: "hidden" }}
            width={item.imgWidth}
            height={item.imgHeight}
            xlinkHref={`https://banesa.flux-ks.com/objektetImg/${buildId}-${item.buildingSide}.jpg`}
            transform={item.imgTransform}
             />
          {
          pathFromDb?.map((item, index) => {
            
            if(item.buildingSide === currentIdx + 1 )
            {
             
              return (
                <>
               { item.pointsType === 'path' ?  <path
                onClick={(e) => {
                  if (
                    item.square <= filter.square.endVal && item.square >=  filter.square.startVal &&
                   item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal &&  
                   (filter.rooms.includes(item.rooms) || filter.rooms.includes('all')) &&
                   item.isSold === false
                  )
                 { 
                  handlePathClick(item);}
                }}
                onMouseEnter={(e) => {
                  if (
                    item.square <= filter.square.endVal && item.square >=  filter.square.startVal &&
                    item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal &&  
                    (filter.rooms.includes(item.rooms) || filter.rooms.includes('all')) &&
                    item.isSold === false
                  )
                    handlePathEnter({ e, item });

                }}
                onMouseLeave={(e) => {
                  handlePathLeave({ e, item });
                }}
                onContextMenu={(e) => {
                  handleContextMenu({ e, item });
                }}
                
                className={
                  item.isSold === true ? ( item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal  ? 'st3': 'st1') : 
                  ( item.square <= filter.square.endVal && item.square >=  filter.square.startVal &&
                    item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal && 
                   (filter.rooms.includes(item.rooms) || filter.rooms.includes('all')) &&
                   item.isSold === false
                  
                    ? "st0"
                    : "st1")
                }
                
                d={item.path}
                id={item.isSold ? item.apartmentId : ''}
               /> : <polygon
               onClick={(e) => {
                 if (
                  item.square <= filter.square.endVal && item.square >=  filter.square.startVal &&
                   item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal &&  
                   (filter.rooms.includes(item.rooms) || filter.rooms.includes('all')) &&
                   item.isSold === false
                 )
                { handlePathClick(item);}
               }}
               onMouseEnter={(e) => {
                 if (
                  item.square <= filter.square.endVal && item.square >=  filter.square.startVal &&
                  item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal && 
                  (filter.rooms.includes(item.rooms) || filter.rooms.includes('all')) &&
                  item.isSold === false
                 )
                   handlePathEnter({ e, item });

               }}
               onMouseLeave={(e) => {
                 handlePathLeave({ e, item });
               }}
               onContextMenu={(e) => {
                 handleContextMenu({ e, item });
               }}
               
               className={
                item.isSold === true ? ( item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal  ? 'st3': 'st1') : 
                ( item.square <= filter.square.endVal && item.square >=  filter.square.startVal &&
                  item.floor <= filter.floor.endVal && item.floor >= filter.floor.startVal && 
                 (filter.rooms.includes(item.rooms) || filter.rooms.includes('all')) &&
                 item.isSold === false
                 
                   ? "st0"
                   : "st1")
               }
               
               points={item.path}
               id={item.isSold ? item.apartmentId : ''}
              />}
               
               </>
                
              );
            }
          })}
          
          </svg>
        </div>
      ))}
      {modalState.open ? (
        <ADetailsModal
          key={modalState.title}
          modalState={modalState}
          setState={setModalState}
        
        />
      ) : null}
     </Box>
     {!showBox ? <Box width={'100%'} display={'flex'} justifyContent={'center'} mt={-2}>
     <Box gap={3} width={'100%'} borderRadius={1} height={40} color={"black"} sx={{backgroundColor: '#FFE99A', ':hover' : {backgroundColor: '#b59701', cursor: 'pointer'}}} display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => {
              dispatch(showSmallDevFilter(true));
            }}>
              <Tune />
              <Typography sx={{fontFamily: 'Montserrat', fontWeight: 400, fontSize: '20px'}} variant="body1">FILTERI</Typography>
          </Box>
     </Box> : null}
        <Collapse in={showBox} sx={{height: '90%'}}>
          <Box
            
            bgcolor={'black'}
          >
            <Filters />
          </Box>
        </Collapse>
        {isADetailModalOpen ? <ADetailModal1 /> : null}
    </Box>
  )
}

export default Test