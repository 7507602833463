const initialCache = {};

export function iCache(itemsToFetch) {
    fetchItemsToCache(itemsToFetch)
}
 function fetchItemsToCache(itemsToFetch) {
  //fetch items and then return them
  
  itemsToFetch.forEach(async (item) => {
    
        const img = new Image();
        img.src = item;
        img.onload = () => {
          // Once the image is loaded, store it in the cache
          initialCache[item] = img;
        };
      
  })
}

function putToCache(itemsToPut) {
    initialCache.push(
        ...initialCache,
        itemsToPut
    )
}
export function getFromCache(key){
    // const returnItem = initialCache[key];
    // if(returnItem){
    //     return returnItem;
    // } 
    // else return key;
    return key
}
