import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
} from '@mui/material';
import { Close, Menu } from '@mui/icons-material';
import { useHref, useNavigate } from 'react-router-dom';

const drawerWidth = '100%';

const useStyles = makeStyles((theme) => ({
 
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: '100vh'
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100vh'
  },
  drawerContainer: {
    overflow: 'auto',
    height: '100vh',
    backgroundColor: 'black',
    color: 'white'
  },
  content: {
    flexGrow: 1,
    padding: 3,
  },
}));

const paths = ['https://flux-ks.com/', '' ,'https://flux-ks.com/galeria', 'https://flux-ks.com/rreth-nesh', 'https://flux-ks.com/na-kontaktoni']

const MobileNavbar = ( { isOpen, setIsOpen } ) => {
  const classes = useStyles();
    const navigate = useNavigate()
  const handleDrawerToggle = () => {
    setIsOpen(false);
  };

  return (

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
      >
        <div className={classes.drawerContainer}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
            <IconButton onClick={handleDrawerToggle} sx={{
                
                color: 'white'
            }}>
                <Close />
            </IconButton>
            </Box>
          <List>
            {['Ballina', 'Projektet', 'Galeria', 'Rreth Nesh', 'Na Kontaktoni'].map((text, index) => (
              <ListItem button key={text} onClick={() => {
                if(paths[index].startsWith('https')){
                    window.location.replace(paths[index])
                }
                else {
                    navigate(paths[index])
                }
              }}>
                <ListItemIcon sx={{color: 'white'}}>
                    <Menu />
                </ListItemIcon>
                <ListItemText primary={text.toUpperCase()} />
              </ListItem>
            )
            )}
          </List>
        </div>
      </Drawer>
   
  );
};

export default MobileNavbar;