import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/consts";
import { tokenConfig, tokenConfigContentMultipart } from "../AuthSlice";
import { RefreshToken } from "../RefreshToken";

const url = `${BASE_URL}/api/apartment`


let refreshingFunc = undefined;

// axios.interceptors.response.use(
//     (res) =>  res,
//     async (error) => {
//         const originalConfig = error.config;
//         const token = localStorage.getItem("token");
        
//         // if we don't have token in local storage or error is not 401 just return error and break req.
//         if (!token || !isUnauthorizedError(error)) {
//             return Promise.reject(error);
//         }

//         try {
//             // the trick here, that `refreshingFunc` is global, e.g. 2 expired requests will get the same function pointer and await same function.
//             if (!refreshingFunc)
//                 refreshingFunc = renewToken();
                
//             const [newToken, newRefreshToken] = await refreshingFunc;

//             localStorage.setItem("token", newToken);
//             localStorage.setItem("refreshToken", newRefreshToken);

//             originalConfig.headers.Authorization = `Bearer ${newToken}`;

//             // retry original request
//             try {
//                 return await axios.request(originalConfig);
//             } catch(innerError) {
//                 // if original req failed with 401 again - it means server returned not valid token for refresh request
//                 if (isUnauthorizedError(innerError)) {
//                     throw innerError;
//                 }                  
//             }

//         } catch (err) {
//             localStorage.removeItem("token");
//             localStorage.removeItem("refreshToken");

//             window.location = `${window.location.origin}/login`;

//         } finally {
//             refreshingFunc = undefined;
//         }
//     },
// )


export const saveApartment = createAsyncThunk(
    "ApartmentSlice/create",
    async(data, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${url}/create`, data, tokenConfig())
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const getAllApartments = createAsyncThunk(
    "ApartmentSlice/getall",
    async(d, { rejectWithValue, dispatch } ) => {
        try {
            const res = await axios.get(`${BASE_URL}/api/apartment/get`)
            return res.data;
        } catch (error) {
            if (
                error.response &&
                error.response.status === 401 &&
                error.response.headers['error'] === 'Access token is expired'
              ){
            try {
              // Try refreshing the token
              
              dispatch(RefreshToken())
            } catch (refreshError) {
              // Handle any error refreshing the token (e.g. network error)
              localStorage.removeItem('session')
              return rejectWithValue('Failed to refresh token');
            }
            // Resend the original request with the new token
            //to be implemented
            const res = await axios.get(`${BASE_URL}/api/apartment/get`)
            return res.data;
          } else {
            // If the error is not 401 or the token has already been refreshed, return the error message
            return rejectWithValue(error.message);
          }
        }
    }
)

export const getAllApartmentsById = createAsyncThunk(
  "ApartmentSlice/getallbyid",
  async(d, { rejectWithValue, dispatch } ) => {
      try {
          const res = await axios.get(`${BASE_URL}/api/apartment/getall/building/${d}`)
          return res.data;
      } catch (error) {
          if (
              error.response &&
              error.response.status === 401 &&
              error.response.headers['error'] === 'Access token is expired'
            ){
          try {
            // Try refreshing the token
            
            dispatch(RefreshToken())
          } catch (refreshError) {
            // Handle any error refreshing the token (e.g. network error)
            localStorage.removeItem('session')
            return rejectWithValue('Failed to refresh token');
          }
          // Resend the original request with the new token
          //to be implemented
          const res = await axios.get(`${BASE_URL}/api/apartment/get`)
          return res.data;
        } else {
          // If the error is not 401 or the token has already been refreshed, return the error message
          return rejectWithValue(error.message);
        }
      }
  }
)

export const getApartmentsSVGByBuilding = createAsyncThunk(
  "ApartmentSlice/getAllByBuilding",
  async(obj, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/api/apartment/getby/${obj}`)
      return res.data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.message)
    }
  }
)

export const updateApartment = createAsyncThunk(
    "ApartmentSlice/update",
    async(obj, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(
              `${BASE_URL}/api/apartment/update`,
              obj,
              tokenConfigContentMultipart()
            );
            return response.data;
        } catch (error) {
          if (
              error.response &&
              error.response.status === 401 &&
              error.response.headers['error'] === 'Access token is expired'
            ){
          try {
            // Try refreshing the token
            
            dispatch(RefreshToken())
          } catch (refreshError) {
            // Handle any error refreshing the token (e.g. network error)
            localStorage.removeItem('session')
            return rejectWithValue('Failed to refresh token');
          }
          // Resend the original request with the new token
          //to be implemented
          const res = await axios.post(
            `${BASE_URL}/api/apartment/update`,
            obj,
            tokenConfigContentMultipart()
          );
          return res.data;
        } else {
          // If the error is not 401 or the token has already been refreshed, return the error message
          return rejectWithValue(error.message);
        }
      }
    }
)

export const updateIsSold = createAsyncThunk(
  "ApartmentSlice/update/sold",
  async(obj, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/api/apartment/update/sold?id=${obj.id}&isSold=${obj.isSold}`, tokenConfig())
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getAllApartmentsForWish = createAsyncThunk(
  "ApartmentSlice/getallforwish",
  async(d, { rejectWithValue, dispatch } ) => {
      try {
          const res = await axios.get(`${BASE_URL}/api/apartment/getbyid?id=${d}`)
          return res.data;
      } catch (error) {
          if (
              error.response &&
              error.response.status === 401 &&
              error.response.headers['error'] === 'Access token is expired'
            ){
          try {
            // Try refreshing the token
            
            dispatch(RefreshToken())
          } catch (refreshError) {
            // Handle any error refreshing the token (e.g. network error)
            localStorage.removeItem('session')
            return rejectWithValue('Failed to refresh token');
          }
          // Resend the original request with the new token
          //to be implemented
          const res = await axios.get(`${BASE_URL}/api/apartment/getbyid?id=${d}`)
          return res.data;
        } else {
          // If the error is not 401 or the token has already been refreshed, return the error message
          return rejectWithValue(error.message);
        }
      }
  }
)
