import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Divider, IconButton, Popover } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Apartment, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getFromCache, useCache } from '../utils/cache';


// {checkIfIsInWishList(id) ? <Favorite sx={{color: 'red', position: 'absolute', top: 1, right: 1}} fontSize='small' /> : null}
const style = {
  
  width: 420,
  height: 'auto',
  pointerEvents: 'all',
  borderRadius: '20px',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'row',
  gap: 2
};
const textStyle = {
  fontFamily: 'Montserrat',
  fontSize: '24px',
  fontWeight: 200
}

 function BDetailModal( { modalState, setState }) {
    const navigate = useNavigate()
  const { open, title, anchorEl, img, id, item } = modalState;

  const dispatch = useDispatch()
  const handleClose = () => { setState({
    ...modalState,
    open: false
  })
  };

  const handleMoreDetailsClicked = () => {
    navigate(`/objektet/${id}`)
  }

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            borderRadius: "17px",
            backgroundColor: "rgba(255, 255, 255, 0.7)", 
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 1, right: 1, pointerEvents: "all" }}
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
        <Box sx={{ ...style }}>
          <Box width={200} pr={"10px"} bgcolor={"rgba(255, 233, 154, 0.85)"}>
            <img
              width={200}
              height={130}
              loading='eager'
              style={{
                objectFit: "cover",
                border: "none",
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 5,
                borderRadius: 5,
              }}
              src={getFromCache(img)}
            />
          </Box>
          <Box
            width={"100%"}
            textAlign={"start"}
            p={1}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={1}
          >
            <Box
              display={"flex"}
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <Box display={"flex"}>
                <Typography style={textStyle}>{"OBJEKTI: "}</Typography>
                <Typography
                  style={{ ...textStyle, marginLeft: 10, fontWeight: "600" }}
                >
                  {id.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
export default BDetailModal
