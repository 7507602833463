import { SvgIcon } from '@mui/material'
import React from 'react'

const RightArrowIcon = (props) => {
  return (
    <SvgIcon {...props}>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.04 73.81">
  <path d="m73.33,36.01c-6.14-7.12-21.02-16.01-56.93-18.93l19.35-10.39c.84-.45,1.45-1.2,1.72-2.11.27-.91.18-1.87-.27-2.7-.58-1.07-1.65-1.77-2.87-1.86-.68-.05-1.35.09-1.94.41L1.87,16.82c-1.08.58-1.77,1.65-1.86,2.87-.09,1.22.44,2.38,1.42,3.11l27.77,20.73c1.57,1.17,3.8.84,4.97-.72.57-.76.81-1.69.67-2.63-.14-.94-.63-1.77-1.39-2.34l-18.39-13.73c33.68,2.6,47.26,10.23,52.7,16.32,3.15,3.53,3.2,6.21,3.17,6.72v.05s0,.05,0,.05c.32,13.53-25.86,19.48-26.12,19.54-.93.2-1.72.75-2.23,1.55-.51.8-.68,1.75-.48,2.68.35,1.62,1.81,2.79,3.47,2.79.26,0,.51-.03.76-.08,1.31-.29,32.07-7.26,31.7-26.31.07-.93.18-5.78-4.68-11.41Z"/>
</svg>
    </SvgIcon>
  )
}

export default RightArrowIcon