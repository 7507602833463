import * as React from 'react';
import Box from '@mui/material/Box';
import { Dialog, DialogContent, DialogTitle, IconButton, Slider, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getSmallDevFilterModalState, setSmallDevFilterModalState } from '../features/SmallDevFilterModal';

const FilterSpeedDial = () => {
    const smallDevFilterModalState = useSelector(getSmallDevFilterModalState)
    const dispatch = useDispatch()
    const [value, setValue] = React.useState([20, 37]);
    function valuetext(value) {
        return `${value}°C`;
      }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box >
        <Dialog PaperProps={{
    style: {
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  }} fullScreen open={smallDevFilterModalState.open}>
        <DialogTitle textAlign={'center'}>Filtri</DialogTitle>
        <DialogContent>
            <IconButton sx={{position: 'absolute', top: 0, right: 1, color: 'white'}} onClick={() => {
                dispatch(setSmallDevFilterModalState(true))
            }}>
                <Close />
            </IconButton>
        <Box sx={{backgroundColor: 'grey', p: 2, height: 'calc(100% - 50px)', borderRadius: 5}}  display={"flex"} justifyContent={'flex-end'} flexDirection={'column'} alignItems={"center"} gap={5} mt={1}>
        <Box width={150}>
          <Typography color={"white"}>Numri Dhomave</Typography>
          <select
           style={{ width: 100, padding: 5}}
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
          </select>
        </Box>
        <Box width={150}>
        <Typography color={"white"}>Kati</Typography>
          <Slider
           getAriaLabel={() => 'Temperature range'}
            step={1}
            direction="row"
            min={1}
            max={250000}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Box>
        <Box width={150}>
        <Typography color={"white"}>Siperfaqja m2</Typography>
          <Slider
            step={1}
            getAriaLabel={() => 'Temperature range'}
            direction="row"
            min={1}
            max={250000}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Box>
      </Box>
        </DialogContent>
    </Dialog>
    </Box>
  )
}

export default FilterSpeedDial