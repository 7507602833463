import { Login, Menu } from '@mui/icons-material';
import { AppBar, Box, Button, Divider, IconButton, Toolbar } from '@mui/material';
import React from 'react'
import CustomMenu from './CustomMenu';
import { useNavigate } from 'react-router-dom';
import MobileNavbar from './MobileNavbar';
import { useState } from 'react';

const Navbar = () => {
  const navigate = useNavigate()
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  return (
    <React.Fragment>
      <AppBar
        sx={{
          backgroundColor: "black",
          borderBottom: "1px solid white",
        }}
        position="fixed"
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              ml: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <img
                style={{ color: "white" }}
                width={"150px"}
                height={"50px"}
                //src="https://flux.roitiv.com/wp-content/uploads/2023/07/Flux-logo-blackYellow-e1690380288586-2048x664.png"
                src='https://flux-ks.com/wp-content/uploads/2023/07/Flux-Logo-whiteYellow-e1696543864342-2048x608.png'
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                alignItems: "center",
                ml: 1,
                gap: 2,
              }}
            >
              <CustomMenu />
              <Button
                sx={{
                  my: 1,
                  backgroundColor: "black",
                  color: "white",
                  border: '2px solid #F6CF3E',
                  fontSize: "13px",
                  fontWeight: 300,
                  padding: '10px 20px',
                  borderRadius: "18px",
                  ":active": {
                    backgroundColor: "#a17f00",
                    color: "white",
                  },
                  ":hover": {
                    backgroundColor: "#a17f00",
                    color: "white",
                  },
                }}
                variant="contained"
              >
                Apartamentet
              </Button>
              <Divider color="white" orientation='vertical' sx={{ height: '50px'}}/>
              <IconButton
                sx={{
                  color: "#F6CF3E",
                }}
              >
                <Menu />
              </IconButton>
              <IconButton onClick={() => navigate('/admin')} sx={{color: '#F6CF3E'}}>
                 <Login />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  gap: 1,
                  md: "none",
                },
              }}
            >
              <IconButton
                sx={{
                  color: "#F6CF3E",
                }}
                onClick={() => setMobileNavbarOpen(true)}
              >
                <Menu />
              </IconButton>
              <IconButton
                sx={{
                  color: "#F6CF3E",
                }}
                onClick={() => navigate('/admin')}
              >
                <Login />
              </IconButton>
            </Box>
            <MobileNavbar isOpen={isMobileNavbarOpen} setIsOpen={setMobileNavbarOpen}/>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}

export default Navbar