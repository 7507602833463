import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllApartmentsForWish } from '../features/Apartment/ApartmentAPI'
import { getApartmentsDataForWishList } from '../features/Apartment/ApartmentSlice'
import { setADetailModalState, setApartmentDetailData } from '../features/ApartmentDetailSlice'
import { checkIfIsInWishList, handleRefreshWishListState } from '../features/WishlistSlice'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BASE_URL } from '../utils/consts'

const WishListItemCard = ({id}) => {
    const dispatch = useDispatch()
    const [apartmentData, setApartmentData] = useState({})
    
    const themee = useTheme()
    const isSmallDev = useMediaQuery(themee.breakpoints.down('md'))
    
   useEffect(() => {
    axios.get(`${BASE_URL}/api/apartment/getbyid?id=${id}`).then(
      res => setApartmentData(res.data)
    ).catch(err => {
      toast.error('Nuk mund te hapet lista e deshirave')
    })
    
   }, [dispatch])   
   const handleOpenModal = () => {
    dispatch(setApartmentDetailData(apartmentData))
    //client modal
    dispatch(setADetailModalState(true))
   }


   const handleRemoveItem = () => {
    if(checkIfIsInWishList(id)) {
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      const filteredList = storedArray.filter((item) => item !== id)
      
      localStorage.setItem('wish', JSON.stringify(filteredList))
      dispatch(handleRefreshWishListState())
    }
  }
}

  return (
    <Box position={'relative'} border={'1px solid rgba(255, 255, 255, 0.45)'} borderRadius={2} p={2} width={isSmallDev ? '95%' : '100%'} height={isSmallDev ? 150 : 255} display={'flex'} gap={3} boxShadow={isSmallDev ? 'rgba(255, 255, 255, 0.55) 1px 4px 10px' : 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}>
        <Box width={isSmallDev ? 120 : 350} height={120} bgcolor={isSmallDev ? 'white' : 'unset'}>
        <img
          width={isSmallDev ? 120 : 350}
          height={isSmallDev ? 120 : 250}
          src={apartmentData?.imageData ? `data:image/png;base64,${apartmentData.imageData}` : ''}
        />
        </Box>
        <Box width={'100%'} display={'flex'} flexDirection={'column'} sx={{justifyContent: {xs: 'flex-start', md: 'center'}, marginLeft: {xs: 0, md: 3}}}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>Njesia:</Typography>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>{apartmentData.name}</Typography>
            </Box>
            <Divider />
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>Siperfaqja:</Typography>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>{apartmentData.square}{'m2'}</Typography>
            </Box>
            <Divider />
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>Kati:</Typography>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>{apartmentData.floor}</Typography>
            </Box>
            <Divider />
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>Tipi:</Typography>
                <Typography sx={{fontSize: {
                  xs: '16px',
                  md: '24px'
                }}}>{apartmentData.rooms}</Typography>
            </Box>
        </Box>
       <Box sx={{
            position: 'absolute',
            bottom: 5,
            right: 10,
            display: 'flex',
            gap: 1
        }} >
       <Button
        variant='contained'
        onClick={handleOpenModal}
        sx={{mt:1, mb:1, backgroundColor: '#f6cf3e', color: 'black', fontSize: '14px', padding: '8px 15px', borderRadius: '7px', ':active' : {
            backgroundColor: '#a17f00',
            color: 'white'
          },
          ':hover' : {
            backgroundColor: '#a17f00',
            color: 'white'
          }}}
        >Me shume</Button>
        <Button
        variant='contained'
        onClick={handleRemoveItem}
        sx={{mt:1, mb:1, backgroundColor: '#f6cf3e', color: 'black', fontSize: '14px', padding: '8px 15px', borderRadius: '7px', ':active' : {
            backgroundColor: '#a17f00',
            color: 'white'
          },
          ':hover' : {
            backgroundColor: '#a17f00',
            color: 'white'
          }}}
        >Fshij</Button>
       </Box>
    </Box>
  )
}

export default WishListItemCard