import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

const TestPage = () => {
  return (
    <div>
        <IconButton 
        sx={{
            position: 'absolute',
            top: 10,
            right: 25,

        }}>
            <Close />
        </IconButton>
    </div>
  )
}

export default TestPage