import { Popover } from '@mui/material'
import React from 'react'
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CheckBox, Close, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setApartmentEditModalData, setApartmentEditModalState } from '../features/ApartmentEditSlice';
import { getSession, handleLoginModalState } from '../features/AuthSlice';
import { getFilterMsg, getFilterStatus, resetStatusAndMsg } from '../features/Apartment/ApartmentSlice';
import { getAllApartments, getAllApartmentsById, updateIsSold } from '../features/Apartment/ApartmentAPI';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { toast } from 'react-toastify';

const ContextMenu = ({ menu, setMenu }) => {
    const dispatch = useDispatch()
    const msg = useSelector(getFilterMsg)
    const status = useSelector(getFilterStatus)
    const { anchorEl, data, id } = menu;
    const handleClose = () => {
        setMenu((prev) => ({
            ...prev,
            open: false,
        }))
    }
    const open = Boolean(anchorEl);
  const idf = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    if (status === 'update_success' || status === 'update_isSold_success') {
      toast.success(
        'Dokumenti u ruaj me sukses', {
          position: 'top-right',
          onClose: () => {
            dispatch(getAllApartmentsById(id));
            setMenu((prev) => ({...prev, open: false}));
            dispatch(resetStatusAndMsg())
          }
        }
      )
      dispatch(setApartmentEditModalState(false));
    }
    if (status === 'update_rejected'|| status === 'update_rejected') {
      toast.error(
        'Gabim ne ruajtjen e dokumentit', {
          position: 'top-right',
          onClose: () => {
            setMenu((prev) => ({...prev, open: false}))
            dispatch(resetStatusAndMsg())
          }
        }
      )
    }
  }, [status]);
  return (
    <Popover
      id={idf}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Paper sx={{ width: 250, maxWidth: '100%' }}>
      <MenuList>
        <MenuItem onClick={() => {
          const dataToUpdate = {
            isSold: !data.isSold,
            id: data.id
          }
          dispatch(updateIsSold(dataToUpdate))
        }}>
          <ListItemIcon>
            {data.isSold ? <CheckBoxOutlineBlankIcon fontSize='small' /> : <CheckBox fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{data.isSold ? 'Etiketo si jo e shitur' : 'Etiketo si e shitur'}</ListItemText>         
        </MenuItem>
        <MenuItem onClick={() => {
          const session = getSession()
          const length = session ? Object.keys(session).length : 0;
          if(length > 1 && session?.isAuth === true){
            dispatch(setApartmentEditModalState(true))
            dispatch(setApartmentEditModalData(data))
            setMenu((prev) => ({...prev, open: false}))
          }
          else{
            dispatch(handleLoginModalState(true) )
          }         
        }}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edito</ListItemText>
        </MenuItem>       
        <Divider />
        <MenuItem onClick={() => { setMenu((prev) => ({...prev, open: false}))}}>
          <ListItemIcon>
            <Close fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mbyll</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
    </Popover>
  );
}

export default ContextMenu