import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    modalState: false,
    wishListItems: [],
    refresh: false,
    wishListItemNr: 0,
}

export const checkIfIsInWishList = (item) => {
    const wishItems = localStorage.getItem('wish')
    const isItemInWishList = wishItems?.includes(item)
    if(isItemInWishList){
        return true
    }
    else{
        return false;
    }
}

const WishlistSlice = createSlice({
    name: 'WishlistSlice',
    initialState,
    reducers: {
        handleWishListModalState(state, action) {
            state.modalState = action.payload
        },
        handleRefreshWishListState(state, action) {
            state.refresh = !state.refresh
        },
        hanleWishListItemNumber(state, action){
            state.wishListItemNr = action.payload;
        },
        handleWishlistItem(state, action) {
            state.wishListItems = action.payload
        }
    }
})

export const {

    handleWishListModalState,
    handleRefreshWishListState,
    hanleWishListItemNumber,
    handleWishlistItem

} = WishlistSlice.actions
export const getWishListModalState = (state) => state.WishlistSlice.modalState;
export const getWishListRefreshState = (state) => state.WishlistSlice.refresh;
export const getWishListItemNr = (state) => state.WishlistSlice.wishListItemNr;
export const getWishListItems = (state) => state.WishlistSlice.wishListItems
export default WishlistSlice.reducer;