import { createSlice } from "@reduxjs/toolkit"
import { getAllApartments, getAllApartmentsById, getAllApartmentsForWish, getApartmentsSVGByBuilding, updateApartment, updateIsSold } from "./ApartmentAPI"
const initialState = {
    status: 'idle',
    msg: '',
    data: [],
    dataForWish: [],
    filter: {
        square: {
            startVal: 40,
            endVal: 300
        },
        rooms:['all'],
        floor: {
            startVal: 1,
            endVal: 7,
        },
        showSmallDev: false,
    },
    apartmentSvgs: [],
    filterShow: false,

}

const ApartmentSlice = createSlice({
    name: 'AparmentSlice',
    initialState,
    reducers: {
        setFilterSquare(state, action) {
            state.filter.square.endVal = action.payload[1]
            state.filter.square.startVal = action.payload[0]
        },
        setFilterFloor(state, action) {
            state.filter.floor.startVal = action.payload[0]
            state.filter.floor.endVal = action.payload[1]
        },
        setFilterRoom(state, action) {
            const exists = state.filter.rooms.includes(action.payload)
            const a = ['1+1', '2+1', '3+1', '4+1']
            
            if(exists) {
                
                state.filter.rooms = state.filter.rooms.filter((item) => !item.includes(action.payload))
                
            }
            else {
                state.filter.rooms.push(action.payload)
                state.filter.rooms = state.filter.rooms.filter((item) => !item.includes('all'))
            }
            
        },
        resetStatus(state){
            state.status = 'idle'
        },
        resetMsg(state){
            state.msg = ''
        },
        resetStatusAndMsg(state){
            state.status = 'idle'
            state.msg = ''
        },
        showSmallDevFilter(state, action) {
            state.filter.showSmallDev = action.payload
        },
        setFilterShow(state, action){
            state.filterShow = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllApartments.pending , (state, action) => {
            state.status = 'get_pending'
        })
        .addCase(getAllApartments.fulfilled , (state, action) => {
            state.status = 'get_success'
            state.data = action.payload
        })
        .addCase(getAllApartments.rejected , (state, action) => {

        })
        .addCase(getAllApartmentsById.pending , (state, action) => {
            state.status = 'get_pending'
        })
        .addCase(getAllApartmentsById.fulfilled , (state, action) => {
            state.status = 'get_success'
            state.data = action.payload
        })
        .addCase(getAllApartmentsById.rejected , (state, action) => {

        })
        
        .addCase(updateApartment.pending , (state, action) => {
            state.status = "update_pending"
        })
        .addCase(updateApartment.fulfilled , (state, action) => {
            state.status = "update_success"
        })
        .addCase(updateApartment.rejected , (state, action) => {
            state.status = "update_rejected"
            state.msg = action.payload
        })

        .addCase(updateIsSold.pending , (state, action) => {
            state.status = "update_isSold_pending"
        })
        .addCase(updateIsSold.fulfilled , (state, action) => {
            state.status = "update_isSold_success"
        })
        .addCase(updateIsSold.rejected , (state, action) => {
            state.status = "update_isSold_rejected"
            state.msg = action.payload
        })
        .addCase(getApartmentsSVGByBuilding.pending , (state, action) => {

        })
        .addCase(getApartmentsSVGByBuilding.fulfilled , (state, action) => {
            state.apartmentSvgs = action.payload
        })
        .addCase(getApartmentsSVGByBuilding.rejected , (state, action) => {

        })
        .addCase(getAllApartmentsForWish.pending ,(state, action) => {

        })
        .addCase(getAllApartmentsForWish.fulfilled ,(state, action) => {
            state.dataForWish = action.payload;
        })
        .addCase(getAllApartmentsForWish.rejected ,(state, action) => {

        })

    },
})

export const { 

    setFilterFloor,
    setFilterRoom,
    setFilterSquare,
    resetMsg,
    resetStatus,
    showSmallDevFilter,
    resetStatusAndMsg,
    setFilterShow,

 } = ApartmentSlice.actions;

export const getApartmentsData = (state) => state.ApartmentSlice.data;
export const getFilterData = (state) => state.ApartmentSlice.filter;
export const getFilterStatus = (state) => state.ApartmentSlice.status;
export const getFilterMsg = (state) => state.ApartmentSlice.msg;
export const getShowSmallDevFilter = (state) => state.ApartmentSlice.filter.showSmallDev;
export const getApartmentsSVG = (state) => state.ApartmentSlice.apartmentSvgs;
export const getFilterShowStatus = (state) => state.ApartmentSlice.filterShow;
export const getApartmentsDataForWishList = (state) => state.ApartmentSlice.dataForWish;

export default ApartmentSlice.reducer;