import React, { useEffect } from 'react'
import Login from '../components/Login'
import { handleLoginModalState } from '../features/AuthSlice'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'

const AdminPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(handleLoginModalState(true))
    }, [])

  return (
    <div>
        <ToastContainer />
        <Login />
    </div>
  )
}

export default AdminPage