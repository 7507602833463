import { Suspense, useEffect } from 'react';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import AdminPage from './pages/AdminPage';
import ApartmentPage from './pages/ApartmentPage';
import Hom from './pages/Hom';
import Test from './pages/Test';
import A12Page from './pages/AddSVG';
import SvgExtractor from './pages/SvgExtractor';
import TestPage from './pages/TestPage';


function App() {
  useEffect(() => {
    document.onkeydown = (e) => {
      if (e.key === 123) {
          e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'I') {
          e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'C') {
          e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'J') {
          e.preventDefault();
      }
      if (e.ctrlKey && e.key == 'U') {
          e.preventDefault();
      }
    };
  }, [])
  
  return (
    <Suspense fallback={<div><h1>Loading...</h1></div>}>
      <Router>
        <Routes>
          <Route path='' element={<Hom />}/>
          <Route path='/objektet/:id' element={<ApartmentPage />}/>
          <Route path='/a1-2' element={<A12Page />}/>
          <Route path='/admin' element={<AdminPage />}/>
          <Route path='/testsvg' element={<Test />}/>
          <Route path='/svg-extractor' element={<SvgExtractor />}/>
          <Route path='/test' element={<TestPage />}/>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
