import {
    Badge,
    Box,
    IconButton,
    Slide,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
  } from "@mui/material";
  import Para from "../assets/Objekti";
  import Filters from "../components/Filters";
  import { getDataToSave } from "../components/convert";
  import { BASE_URL } from "../utils/consts";
  import axios from "axios";
  import { ToastContainer } from "react-toastify";
  import { tokenConfig } from "../features/AuthSlice";
  import {
    getFilterShowStatus,
    getShowSmallDevFilter,
    setFilterShow,
  } from "../features/Apartment/ApartmentSlice";
  import { useDispatch, useSelector } from "react-redux";
  import Test from "./Test";
  import { Cancel, Close, CloseFullscreen, Favorite } from "@mui/icons-material";
  import {
    getWishListItemNr,
    getWishListModalState,
    getWishListRefreshState,
    handleWishListModalState,
    hanleWishListItemNumber,
  } from "../features/WishlistSlice";
  import WishListModal from "../components/WishListModal";
  import { useNavigate, useParams } from "react-router-dom";
  import '../App.css'
  import FilterButton from "../components/FilterButton";
  import { useEffect } from "react";
import Navbar from "../components/Navbar";
  
  function HideOnScroll(props) {
    const { children, window } = props;
    
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide
        appear={false}
        direction="left"
        easing={"0.1s ease out"}
        in={!trigger}
      >
        {children}
      </Slide>
    );
  }
  function ApartmentPage(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallDev = useMediaQuery(theme.breakpoints.down("md"));
    const showBox = useSelector(getShowSmallDevFilter);
    const refreshWishList = useSelector(getWishListRefreshState)
    const wishListItemsNumber = useSelector(getWishListItemNr)
    const dispatch = useDispatch();
    const isWishListModalOpen = useSelector(getWishListModalState);
    const {id} = useParams()
    const isFilterOpened = useSelector(getFilterShowStatus)
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const apartmentData = getDataToSave();
      apartmentData.forEach((item) => {
        const formData = new FormData();
        formData.append("rooms", item.rooms);
        formData.append("isSold", false);
        formData.append("floor", item.floor);
        formData.append("square", item.square);
        formData.append("name", item.name);
        formData.append("apartmentNumber", item.apartmentNumber);
        formData.append("style", "");
        formData.append("className", "");
        formData.append("imageData", null);
        formData.append("path", item.path);
        formData.append("apartmentId", item.apartmentNumber);
        formData.append("balconySquare", item.balconySquare);
        formData.append("buildingNr", 0);
        const response = axios
          .post(`${BASE_URL}/api/apartment/createall`, formData, tokenConfig())
          .then((res) => {
            if (response.status === 200) {
              console.log("User created successfully");
            }
          })
          .catch((error) => {
            console.error("An error occurred", error);
          });
      });
    };
    useEffect(() => {
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
      dispatch(hanleWishListItemNumber(storedArray ? storedArray.length : 0))
    }, [refreshWishList])
    return (
      <>
     
        {isSmallDev ? (
          <Test buildId={id}/>
        ) : (
          <Box
            display={"flex"}
            sx={{ backgroundColor: "black" }}
            flexDirection={"row"}
            height={"100vh"}
            overflow={"hidden"}
            position={'relative'}
          >
            <ToastContainer />
            {isFilterOpened ? 
        <IconButton sx={{
          position: 'absolute',
          top: 5,
          right: 15,
          color: "#f6cf3e",
          zIndex: 99999,
        }}
        onClick={() => {
          dispatch(setFilterShow(false))
        }}
        >
          <Cancel fontSize="large"/>
        </IconButton> : null}
            <Box width={'100%'} height={"100vh"}>
              <Para buildId={id}/>
            </Box>
            {isFilterOpened ? 
              <Box
              maxWidth={400}
              width={400}
              height={"100vh"}
              sx={{ backgroundColor: "black", borderRadius: 1 }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
             <Filters />
            </Box>
               : <Box position={'relative'} height={'100%'} width={'50px'}>
                
                < FilterButton />
                </Box>}
            
          </Box>
        )}
        {showBox || isFilterOpened ? null : <IconButton
          title="Mbyll"
          onClick={() => {
            navigate("/");
          }}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#f6cf3e",
            border: "1px solid #f6cf3e",
          }}
          
        >
         <Close />
        </IconButton>}
        {showBox || isFilterOpened ? null : <Badge color="error" variant="standard" badgeContent={wishListItemsNumber} sx={{
            position: "absolute",
            top: 65,
            right: 10,
            color: "#f6cf3e",
            border: "1px solid #f6cf3e",
            borderRadius: 50
          }}>
        <IconButton
        className="wishListButton"
        sx={{color: 'white', border: '1px solid white'}}
          title="Wishlist"
          onClick={() => {
            dispatch(handleWishListModalState(true));
          }}
          variant="contained"
          
        >
          <Favorite />
        </IconButton>
        </Badge>}
        {isWishListModalOpen ? <WishListModal /> : null}
        
      </>
    );
  }
  
  export default ApartmentPage;
  